import React, { useEffect, useState } from "react";
import { V4HeaderContainer } from "./header.styled";
import ConcashLogo from "../../../assets/images/logo-negative.png";
import { V4ButtonSubmit, V4HamburgButton } from "../commons/Button";
import { sanitizeParams } from "../../../utils/sanitize";
import { V4TextRegular } from "../commons/Tipography";
import { Backdrop } from "@mui/material";
import { MenuNavMobile } from "../../shared/Header/styles.mobile";
import { V4MenuNavMobile } from "./V4MenuNav";
import { V4OptOut } from "../OptOut";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { MdOutlineCookie } from "react-icons/md";
import { showCookies } from "../../../store/optout/optout.slide";

export const V4Header = () => {
  const { optout }: any = useSelector((state) => state);
  const dispatch = useDispatch();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [menuNav, setMenuNav] = useState(false);
  const [queryParams, setQueryParams] = useState<any>();

  useEffect(() => {
    const text = sanitizeParams(window.location.search);
    setQueryParams(text);
  }, [queryParams]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 500);
  }, [window.innerWidth]);

  const rolarPagina = () => {
    window.scrollBy({
      top: 400,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <V4HeaderContainer>
      {optout.iniciou ? (
        <>
          <button
          onClick={() => dispatch(showCookies())}
            style={{
              border: 'none',
              display: "flex",
              width: 50,
              height: 50,
              borderRadius: "50%",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              bottom: 10,
              left: 20,
              backgroundColor: "#FFF",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              cursor: "pointer",
            }}
          >
            <MdOutlineCookie size={38} color="#787878" />
          </button>
        </>
      ) : (
        <V4OptOut />
      )}
      {width > 1024 && ( // Desktop
        <>
          <img
            src={ConcashLogo}
            alt="Concash"
            width={88}
            onClick={() => (window.location.href = "/")}
          />
          <div style={{ display: "flex", width: "100%" }}>
            <ul
              style={{
                display: "flex",
                gap: 32,
                padding: "16px",
                fontFamily: "Moderat",
              }}
            >
              <li>
                <a
                  href="https://blog.concash.com.br"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="/sobre"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Sobre nós
                </a>
              </li>
              <li>
                <a
                  href="/faq"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Perguntas frequentes
                </a>
              </li>
              <li>
                <a
                  href="/contatos"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Fale conosco
                </a>
              </li>
            </ul>
          </div>
          <div style={{ display: "flex", width: "360px", gap: 20 }}>
            <a
              href="https://parceiros.concash.com.br/parceiros-b2b"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <V4TextRegular style={{ textWrap: "nowrap" }}>
                {"Seja parceiro >"}
              </V4TextRegular>
            </a>
            <V4ButtonSubmit
              type="LINK"
              text="Vender consórcio"
              width={180}
              radius={8}
              color='#000'
              height={32}
              link={"/onboarding/inicio"}
            />
          </div>
        </>
      )}
      {width <= 1024 && ( // Mobile
        <>
          <Backdrop
            open={menuNav}
            style={{ alignItems: "flex-end", padding: 0, zIndex: 999 }}
          >
            <V4MenuNavMobile action={() => setMenuNav(false)} />
          </Backdrop>
          <V4HamburgButton onclick={() => setMenuNav(true)} />
          <img
            src={ConcashLogo}
            alt="Concash"
            width={88}
            onClick={() => (window.location.href = "/")}
          />
        </>
      )}
    </V4HeaderContainer>
  );
};
