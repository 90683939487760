import React from "react";

import {
  ContainerDesktop,
  ContainerMobile,
  SubtitleDesktop,
  SubtitleMobile,
  TitleDesktop,
  TitleMobile,
} from "./styles";

import { WhatsAppLargeButton } from "../Buttons/button";
import House from "../../assets/images/house.png";
import { AccordionFAQ, AccordionFAQMobile } from "../Accordion";
import { V3ButtonLink } from "../../styles/themes/components/button";
import { V4Footer } from "../V4";


const BlocoFAQ: React.FC = (props: any) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 500);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {isMobile ? (
        <>
          <ContainerMobile>
            <section>
              <TitleMobile>Dúvidas frequentes</TitleMobile>
              <SubtitleMobile>
                Tem dúvidas sobre consórcios ou sobre a venda da sua cota? A
                gente traz aqui as respostas para as perguntas mais frequentes.
                Caso queira mais informações, entre em contato conosco!
              </SubtitleMobile>
              <img
                loading="lazy"
                src={House}
                style={{ marginTop: 30, width: 330 }}
                width={330}
                height={160}
                alt="house"
              />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <AccordionFAQMobile
                  active={expanded === "panel1"}
                  changeActive={handleChange("panel1")}
                  title={"Como eu faço para adquirir um consórcio?"}
                  content="O consórcio é uma das melhores alternativas para quem ficar atento no momento da aquisição. Busque sempre uma                        Administradora de consórcios que tenha credibilidade no
                        mercado. No site do Banco Central você pode conferir as Administradoras regulares. Quando escolher a sua Administradora, faça uma simulação do seu tipo de
                        consórcio e escolha a opção que melhor se encaixa no seu
                        orçamento e da sua família. Depois é só ofertar o lance
                        ou aguardar os sorteios para adquirir o seu bem ou
                        serviço."
                />
                <AccordionFAQMobile
                  active={expanded === "panel2"}
                  changeActive={handleChange("panel2")}
                  title={"Como eu faço para solicitar o extrato da minha cota?"}
                  content={
                    "Entre em contato com a central de atendimento da sua administradora ou acesse o portal do consorciado da sua administradora."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel3"}
                  changeActive={handleChange("panel3")}
                  title={"Como posso entrar em contato com a Concash?"}
                  content={
                    "Se você está em dúvida se a Concash é confiável, temos uma equipe disponível para atendê-lo em todas as etapas para você vender consórcio. Por telefone e WhatsApp, estamos à disposição através do número (11) 4040-5686. Você também pode nos enviar e-mail pelo voce@concash.com.br. O nosso horário de atendimento é de segunda a sexta, das 9h às 18h. Aguardamos o seu contato!"
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel4"}
                  changeActive={handleChange("panel4")}
                  title={
                    "Em quanto tempo a administradora me devolve o dinheiro?"
                  }
                  content={
                    "Caso você tenha cancelado o seu consórcio, as administradoras adotam duas formas para você retirar o que já pagou através dos sorteios dos cancelados ou no encerramento do grupo. Se tiver interesse em antecipar esse valor envie uma mensagem no nosso time via WhatsApp (11) 4040-5686."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel5"}
                  changeActive={handleChange("panel5")}
                  title={
                    "Em quanto tempo a Administradora disponibiliza o dinheiro do meu consórcio cancelado?"
                  }
                  content={
                    "Se você quer vender cota de consórcio cancelada, existem duas possibilidades de recuperar dinheiro consórcio com a sua Administradora: aguardar o término do grupo ou um eventual sorteio. Mas com a Concash é confiável a venda de consorcio cancelado e você pode recuperar o valor em poucos dias. Solicite uma proposta aqui no nosso site e receba o dinheiro à vista, com o acompanhamento da sua Administradora."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel6"}
                  changeActive={handleChange("panel6")}
                  title={"Como posso vender o meu consórcio?"}
                  content={
                    <>
                      <p>
                        A Concash tem as melhores condições para você vender
                        consórcio online com segurança, rapidez e transparência!
                        Se você precisou mudar os seus planos e está procurando
                        um parceiro da sua Administradora para vender cota de
                        consórcio cancelada em poucos dias, a Concash é
                        confiável e basta seguir esses passos:
                      </p>
                      <p>
                        1.Solicite a sua proposta de venda preenchendo o nosso
                        formulário disponível no site;
                      </p>
                      <p>
                        2.Você receberá a melhor proposta para vender consórcio
                        com segurança e rapidez, em um processo totalmente
                        online;
                      </p>
                      <p>
                        3.Aceitando a proposta ofertada, você envia os
                        documentos solicitados e o resto é com a gente!
                        Entraremos em contato com a sua Administradora, e em
                        poucos dias será realizada a transferência do seu
                        crédito.
                      </p>
                    </>
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel7"}
                  changeActive={handleChange("panel7")}
                  title={"Qual o percentual que vocês pagam?"}
                  content={
                    "Não temos um percentual fixo para as operações, entendemos que cada cliente possui sua particularidade, com isso realizamos um atendimento personalizado que visa ofertar propostas justas aos clientes. Estamos trabalhando com sonhos."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel8"}
                  changeActive={handleChange("panel8")}
                  title={
                    "Posso receber o valor da minha proposta em conta de terceiros?"
                  }
                  content={
                    "Prezamos pela segurança em todo o processo, por isso não é permitido realizar o pagamento da venda da cota em contas de terceiros. A transferência é realizada apenas para a conta do titular do consórcio."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel9"}
                  changeActive={handleChange("panel9")}
                  title={"O que é contrato de cessão?"}
                  content={
                    "O contrato de cessão de cotas de consórcio é um instrumento pelo qual o cotista (cedente) autoriza a transferência de titularidade da sua cota para outro titular da cota (cessionário). Ou seja, quem está transferindo o contrato é considerado o cedente, e o cessionário é quem adquire a cota, e será o novo responsável por todas as obrigações junto à Administradora. Se você busca um cessionário para a sua cota de consórcio, a Concash é a opção mais segura e rápida para recuperar o seu crédito com segurança."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel10"}
                  changeActive={handleChange("panel10")}
                  title={"Qual o valor da taxa de transferência?"}
                  content={
                    "Isso varia de acordo com cada Administradora de consórcio. Para saber mais detalhes, entre em contato com a sua Administradora."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel11"}
                  changeActive={handleChange("panel11")}
                  title={
                    "A Concash realiza empréstimos ou financiamento de veículos?"
                  }
                  content={
                    "Atualmente o nosso objetivo é oferecer alternativas seguras para consorciados desistentes que precisam resgatar o dinheiro da sua cota antes do término do seu grupo. Não realizamos empréstimos ou qualquer tipo de financiamento."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel12"}
                  changeActive={handleChange("panel12")}
                  title={
                    "Quando eu vendo a minha cota para a Concash, ela permanece no meu nome?"
                  }
                  content={
                    "Não. Quando intermediamos a venda da sua cota, ela não permanece no seu nome, e passa a ter um novo responsável junto à Administradora."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel13"}
                  changeActive={handleChange("panel13")}
                  title={
                    "Quanto tempo demora o pagamento da venda da minha cota?"
                  }
                  content={
                    "O prazo para pagamento da venda da sua cota varia de acordo com a Administradora. Quando recebemos toda a documentação necessária, o processo de transferência da cota é solicitado junto à sua Administradora, e informamos o prazo de recebimento. Após a conclusão desta etapa, o depósito do valor ocorrerá em poucos dias úteis. Algumas Dicas: Quanto mais rápido enviar a documentação completa e legível mais rápido será o pagamento e só pagamos na conta do titular da cota."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel14"}
                  changeActive={handleChange("panel14")}
                  title={"É seguro e dentro da lei vender consórcio?"}
                  content={
                    "Sim, está previsto no artigo 13 da lei nº 11.795/08, que dispõe sobre o Sistema de Consórcio, que o processo de venda de uma cota de consórcio para terceiros é permitido, contanto que seja validado pela Administradora."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel15"}
                  changeActive={handleChange("panel15")}
                  title={
                    "Posso receber o que paguei no meu consórcio antes do encerramento do meu grupo?"
                  }
                  content={
                    "Sim. Para antecipar o recebimento do seu crédito, solicite uma proposta conosco aqui no site da Concash. Em poucos dias você recupera o valor investido no consórcio."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel16"}
                  changeActive={handleChange("panel16")}
                  title={
                    "Qual a orientação do Banco Central do Brasil quanto ao recebimento do crédito em caso de exclusão do grupo de consórcio?"
                  }
                  content={
                    "De acordo com a Circular nº 3.432, do Bacen, o consorciado será excluído do grupo quando manifesta a intenção de não permanecer no mesmo ou quando deixa de cumprir as obrigações financeiras previstas no contrato. Assim, a restituição do valor pago pelo consorciado excluído deverá ser negociada diretamente com a Administradora."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel17"}
                  changeActive={handleChange("panel17")}
                  title={
                    "Como ocorre o sorteio dos consorciados excluídos? Pode dar lance?"
                  }
                  content={
                    " Os consorciados que adquiriram a sua cota após o dia 10 de fevereiro de 2009, quando foi sancionada a lei nº 11.795/08, participam de sorteios mensalmente, seguindo uma ordem: 1º sorteio de cota regular, 2º sorteio de cotas excluídas e 3º lances fixos ou livres. O consorciado com a cota cancelada não pode ofertar lances."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel18"}
                  changeActive={handleChange("panel18")}
                  title={
                    "Posso fazer a desistência de consórcio?"
                  }
                  content={
                    "Sim, é possível fazer a desistência de consórcio, mas é preciso ficar atento a alguns aspectos importantes a serem considerados. A desistência pode ocorrer a qualquer momento, mas o consorciado terá que seguir as regras e procedimentos estabelecidos pela administradora e pela legislação vigente. Leia atentamente o seu contrato ou entre em contato com a sua administradora para obter informações detalhadas sobre o procedimento de desistência de consórcio e suas implicações."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel19"}
                  changeActive={handleChange("panel19")}
                  title={
                    "A Concash é confiável?"
                  }
                  content={
                    "Se você quer saber se a Concash é confiável, fique atento às informações que vamos trazer aqui. Primeiro, basta conferir a reputação da Concash no site Reclame Aqui. Ele é uma das plataformas disponíveis para que o cliente possa verificar como a empresa atende às suas demandas. Na plataforma temos uma reputação que demonstra que a Concash é confiável e tem um serviço seguro. Além disso, mais de 50 mil famílias que precisaram desistir das suas cotas já foram atendidas pela Concash, e alguns depoimentos de clientes estão disponíveis no site. Por fim, todas as negociações de cotas são intermediadas pelas administradoras parceiras, reforçando a segurança e idoneidade do processo."
                  }
                />
                <AccordionFAQMobile
                  active={expanded === "panel20"}
                  changeActive={handleChange("panel20")}
                  title={
                    "Qual a empresa que compra consórcio cancelado?"
                  }
                  content={
                   <>
                    <p>A Concash compra consórcio cancelado e realiza todo o processo com o acompanhamento da sua administradora. Se você tem uma cota ativa, inadimplente ou cancelada, <a href="/venderconsorcio">clique aqui</a> e simule a venda da sua cota, seja de imóvel, automóvel, motocicleta ou serviço. Oferecemos sempre a melhor proposta do mercado.</p>
                   </>
                  }
                />

                <div className="cell-contact">
                  <span className="contact-title">Ainda tem dúvidas?</span>
                  <WhatsAppLargeButton href="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0" />
                </div>
                <div className="cell-contact" style={{ marginBottom: 250 }}>
                  <span className="contact-title">
                    Quer vender o seu consórcio?
                  </span>
                  <V3ButtonLink
                    estilo="primary"
                    href="/onboarding/inicio"
                    text="QUERO VENDER"
                    width={"100%"}
                    fontSz={20}
                    height={60}
                  />
                </div>
              </div>
            </section>
          </ContainerMobile>
          <V4Footer />
        </>
      ) : (
        <>
          <ContainerDesktop style={{ marginTop: 60 }}>
            <section>
              <TitleDesktop>Dúvidas frequentes</TitleDesktop>
              <SubtitleDesktop>
                Tem dúvidas sobre consórcios ou sobre a venda da sua cota? A
                gente traz aqui as respostas para as perguntas mais frequentes.
                Caso queira mais informações, entre em contato conosco!
              </SubtitleDesktop>
              <img
                loading="lazy"
                src={House}
                style={{ marginTop: 40 }}
                width={1200}
                height={400}
                alt="House-"
              />
              <div style={{ margin: "20px 283px" }}>
                <div>
                  <AccordionFAQ
                    active={expanded === "panel1"}
                    changeActive={handleChange("panel1")}
                    title={"Como eu faço para adquirir um consórcio?"}
                    content="O consórcio é uma das melhores alternativas para quem ficar atento no momento da aquisição. Busque sempre uma                        Administradora de consórcios que tenha credibilidade no
                        mercado. No site do Banco Central você pode conferir as Administradoras regulares. Quando escolher a sua Administradora, faça uma simulação do seu tipo de
                        consórcio e escolha a opção que melhor se encaixa no seu
                        orçamento e da sua família. Depois é só ofertar o lance
                        ou aguardar os sorteios para adquirir o seu bem ou
                        serviço."
                  />
                  <AccordionFAQ
                    active={expanded === "panel2"}
                    changeActive={handleChange("panel2")}
                    title={
                      "Como eu faço para solicitar o extrato da minha cota?"
                    }
                    content={
                      "Entre em contato com a central de atendimento da sua administradora ou acesse o portal do consorciado da sua administradora."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel3"}
                    changeActive={handleChange("panel3")}
                    title={"Como posso entrar em contato com a Concash?"}
                    content={
                      "Se você está em dúvida se a Concash é confiável, temos uma equipe disponível para atendê-lo em todas as etapas para você vender consórcio. Por telefone e WhatsApp, estamos à disposição através do número (11) 4040-5686. Você também pode nos enviar e-mail pelo voce@concash.com.br. O nosso horário de atendimento é de segunda a sexta, das 9h às 18h. Aguardamos o seu contato!"
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel4"}
                    changeActive={handleChange("panel4")}
                    title={
                      "Em quanto tempo a administradora me devolve o dinheiro?"
                    }
                    content={
                      "Caso você tenha cancelado o seu consórcio, as administradoras adotam duas formas para você retirar o que já pagou através dos sorteios dos cancelados ou no encerramento do grupo. Se tiver interesse em antecipar esse valor envie uma mensagem no nosso time via WhatsApp (11) 4040-5686."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel5"}
                    changeActive={handleChange("panel5")}
                    title={
                      "Em quanto tempo a Administradora disponibiliza o dinheiro do meu consórcio cancelado?"
                    }
                    content={
                      "Se você quer vender cota de consórcio cancelada, existem duas possibilidades de recuperar dinheiro consórcio com a sua Administradora: aguardar o término do grupo ou um eventual sorteio. Mas com a Concash é confiável a venda de consorcio cancelado e você pode recuperar o valor em poucos dias. Solicite uma proposta aqui no nosso site e receba o dinheiro à vista, com o acompanhamento da sua Administradora."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel6"}
                    changeActive={handleChange("panel6")}
                    title={"Como posso vender o meu consórcio?"}
                    content={
                      <>
                      <p>
                        A Concash tem as melhores condições para você vender
                        consórcio online com segurança, rapidez e transparência!
                        Se você precisou mudar os seus planos e está procurando
                        um parceiro da sua Administradora para vender cota de
                        consórcio cancelada em poucos dias, a Concash é
                        confiável e basta seguir esses passos:
                      </p>
                      <br />
                      <p>
                        1.Solicite a sua proposta de venda preenchendo o nosso
                        formulário disponível no site;
                      </p>
                      <br />
                      <p>
                        2.Você receberá a melhor proposta para vender consórcio
                        com segurança e rapidez, em um processo totalmente
                        online;
                      </p>
                      <br />
                      <p>
                        3.Aceitando a proposta ofertada, você envia os
                        documentos solicitados e o resto é com a gente!
                        Entraremos em contato com a sua Administradora, e em
                        poucos dias será realizada a transferência do seu
                        crédito.
                      </p>
                    </>
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel7"}
                    changeActive={handleChange("panel7")}
                    title={"Qual o percentual que vocês pagam?"}
                    content={
                      "Não temos um percentual fixo para as operações, entendemos que cada cliente possui sua particularidade, com isso realizamos um atendimento personalizado que visa ofertar propostas justas aos clientes. Estamos trabalhando com sonhos."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel8"}
                    changeActive={handleChange("panel8")}
                    title={
                      "Posso receber o valor da minha proposta em conta de terceiros?"
                    }
                    content={
                      "Prezamos pela segurança em todo o processo, por isso não é permitido realizar o pagamento da venda da cota em contas de terceiros. A transferência é realizada apenas para a conta do titular do consórcio."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel9"}
                    changeActive={handleChange("panel9")}
                    title={"O que é contrato de cessão?"}
                    content={
                      "O contrato de cessão de cotas de consórcio é um instrumento pelo qual o cotista (cedente) autoriza a transferência de titularidade da sua cota para outro titular da cota (cessionário). Ou seja, quem está transferindo o contrato é considerado o cedente, e o cessionário é quem adquire a cota, e será o novo responsável por todas as obrigações junto à Administradora. Se você busca um cessionário para a sua cota de consórcio, a Concash é a opção mais segura e rápida para recuperar o seu crédito com segurança."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel10"}
                    changeActive={handleChange("panel10")}
                    title={"Qual o valor da taxa de transferência?"}
                    content={
                      "Isso varia de acordo com cada Administradora de consórcio. Para saber mais detalhes, entre em contato com a sua Administradora."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel11"}
                    changeActive={handleChange("panel11")}
                    title={
                      "A Concash realiza empréstimos ou financiamento de veículos?"
                    }
                    content={
                      "Atualmente o nosso objetivo é oferecer alternativas seguras para consorciados desistentes que precisam resgatar o dinheiro da sua cota antes do término do seu grupo. Não realizamos empréstimos ou qualquer tipo de financiamento."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel12"}
                    changeActive={handleChange("panel12")}
                    title={
                      "Quando eu vendo a minha cota para a Concash, ela permanece no meu nome?"
                    }
                    content={
                      "Não. Quando intermediamos a venda da sua cota, ela não permanece no seu nome, e passa a ter um novo responsável junto à Administradora."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel13"}
                    changeActive={handleChange("panel13")}
                    title={
                      "Quanto tempo demora o pagamento da venda da minha cota?"
                    }
                    content={
                      "O prazo para pagamento da venda da sua cota varia de acordo com a Administradora. Quando recebemos toda a documentação necessária, o processo de transferência da cota é solicitado junto à sua Administradora, e informamos o prazo de recebimento. Após a conclusão desta etapa, o depósito do valor ocorrerá em poucos dias úteis. Algumas Dicas: Quanto mais rápido enviar a documentação completa e legível mais rápido será o pagamento e só pagamos na conta do titular da cota."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel14"}
                    changeActive={handleChange("panel14")}
                    title={"É seguro e dentro da lei vender consórcio?"}
                    content={
                      "Sim, está previsto no artigo 13 da lei nº 11.795/08, que dispõe sobre o Sistema de Consórcio, que o processo de venda de uma cota de consórcio para terceiros é permitido, contanto que seja validado pela Administradora."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel15"}
                    changeActive={handleChange("panel15")}
                    title={
                      "Posso receber o que paguei no meu consórcio antes do encerramento do meu grupo?"
                    }
                    content={
                      "Sim. Para antecipar o recebimento do seu crédito, solicite uma proposta conosco aqui no site da Concash. Em poucos dias você recupera o valor investido no consórcio."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel16"}
                    changeActive={handleChange("panel16")}
                    title={
                      "Qual a orientação do Banco Central do Brasil quanto ao recebimento do crédito em caso de exclusão do grupo de consórcio?"
                    }
                    content={
                      "De acordo com a Circular nº 3.432, do Bacen, o consorciado será excluído do grupo quando manifesta a intenção de não permanecer no mesmo ou quando deixa de cumprir as obrigações financeiras previstas no contrato. Assim, a restituição do valor pago pelo consorciado excluído deverá ser negociada diretamente com a Administradora."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel17"}
                    changeActive={handleChange("panel17")}
                    title={
                      "Como ocorre o sorteio dos consorciados excluídos? Pode dar lance?"
                    }
                    content={
                      " Os consorciados que adquiriram a sua cota após o dia 10 de fevereiro de 2009, quando foi sancionada a lei nº 11.795/08, participam de sorteios mensalmente, seguindo uma ordem: 1º sorteio de cota regular, 2º sorteio de cotas excluídas e 3º lances fixos ou livres. O consorciado com a cota cancelada não pode ofertar lances."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel18"}
                    changeActive={handleChange("panel18")}
                    title={
                      "Posso fazer a desistência de consórcio?"
                    }
                    content={
                      "Sim, é possível fazer a desistência de consórcio, mas é preciso ficar atento a alguns aspectos importantes a serem considerados. A desistência pode ocorrer a qualquer momento, mas o consorciado terá que seguir as regras e procedimentos estabelecidos pela administradora e pela legislação vigente. Leia atentamente o seu contrato ou entre em contato com a sua administradora para obter informações detalhadas sobre o procedimento de desistência de consórcio e suas implicações."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel19"}
                    changeActive={handleChange("panel19")}
                    title={
                      "A Concash é confiável?"
                    }
                    content={
                      "Se você quer saber se a Concash é confiável, fique atento às informações que vamos trazer aqui. Primeiro, basta conferir a reputação da Concash no site Reclame Aqui. Ele é uma das plataformas disponíveis para que o cliente possa verificar como a empresa atende às suas demandas. Na plataforma temos uma reputação que demonstra que a Concash é confiável e tem um serviço seguro. Além disso, mais de 50 mil famílias que precisaram desistir das suas cotas já foram atendidas pela Concash, e alguns depoimentos de clientes estão disponíveis no site. Por fim, todas as negociações de cotas são intermediadas pelas administradoras parceiras, reforçando a segurança e idoneidade do processo."
                    }
                  />
                  <AccordionFAQ
                    active={expanded === "panel20"}
                    changeActive={handleChange("panel20")}
                    title={
                      "Qual a empresa que compra consórcio cancelado?"
                    }
                    content={
                    <>
                      <p>A Concash compra consórcio cancelado e realiza todo o processo com o acompanhamento da sua administradora. Se você tem uma cota ativa, inadimplente ou cancelada, <a href="/venderconsorcio">clique aqui</a> e simule a venda da sua cota, seja de imóvel, automóvel, motocicleta ou serviço. Oferecemos sempre a melhor proposta do mercado.</p>
                    </>
                    }
                  />
                </div>
                <div className="box-inf">
                  <div className="cell-contact">
                    <span className="contact-title">Ainda tem dúvidas?</span>
                    <WhatsAppLargeButton href="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0" />
                  </div>
                  <div className="cell-contact">
                    <span className="contact-title">
                      Quer vender o seu consórcio?
                    </span>
                    <V3ButtonLink
                      estilo="primary"
                      href="/onboarding/inicio"
                      text="QUERO VENDER"
                      fontSz={20}
                      width={298}
                      height={60}
                    />
                  </div>
                </div>
              </div>
            </section>
          </ContainerDesktop>
          <V4Footer />
        </>
      )}
    </>
  );
};

export default BlocoFAQ;
