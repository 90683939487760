import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Landing from "./pages/Landing/LandingPage";
import LandingCNP from "./pages/Landing/LandingPage/Home/landingcnp";
import LandingBB from "./pages/Landing/LandingPage/Home/landingbb";
import LandingAdm from "./pages/Landing/LandingPage/Home/landing";
import Parceiro from "./pages/Landing/LandingPage/Home/parceiro";
import SobreAConCash from "./pages/SobreAConCash";
import Page404 from "./pages/Page404";
import pack from "../package.json";
import OptIn from "./pages/OptIn";
import { SucessoOferta } from "./pages/OptIn/steps/sucess";
import { RejeitouOferta } from "./pages/OptIn/steps/reject";
import VenderConsorcio from "./pages/VenderConsorcio";
import VenderConsorcioV2 from "./pages/VenderConsorcioV2";
import { SucessoVender } from "./pages/VenderConsorcio/steps/sucess";
import { SucessoVenderV2 } from "./pages/VenderConsorcioV2/steps/sucess";
import FAQ from "./pages/FAQ";
import { NaoAtendeVender } from "./pages/VenderConsorcio/steps/naoAtende";
import LandingRandon from "./pages/Landing/LandingPage/Home/landingrandon";
import LandingBradesco from "./pages/Landing/LandingPage/Home/landingBradesco";
import V4Page from "./pages/v4";
import V4LandingAdmPage from "./pages/v4/landing-administradora";
import V4OnboardingStart from "./pages/v4/onboarding/start";
import V4OnboardingSelectAdmininstradora from "./pages/v4/onboarding/step2";
import V4OnboardingSelectTypePerson from "./pages/v4/onboarding/step3";
import V4OnboardingDocuments from "./pages/v4/onboarding/step4";
import V4OnboardingSucesso from "./pages/v4/onboarding/sucesso";
import V4LandingOutrasAdmsPage from "./pages/v4/landing-outras";

const RoutesWeb = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Home} path="/old" />
        <Route component={V4Page} path="/" exact />
        <Route component={About} path="/sobre" />
        <Route component={Contact} path="/contatos" />
        {/* <Route component={Landing} path="/santander" /> */}
        <Route component={V4LandingAdmPage} path="/santander" />
        <Route component={V4LandingAdmPage} path="/chevrolet" />
        <Route component={V4LandingAdmPage} path="/porto" />
        <Route component={V4LandingAdmPage} path="/canopus" />
        <Route component={V4LandingAdmPage} path="/volkswagen" />
        <Route component={V4LandingAdmPage} path="/randon" />
        <Route component={V4LandingAdmPage} path="/daf" />
        <Route path="/landing/:id" render={({match}) => <V4LandingOutrasAdmsPage id={match.params.id} />} />
        <Route component={LandingCNP} path="/cnp" /> 
        <Route component={V4LandingAdmPage} path="/compracota" /> 
        <Route component={V4LandingAdmPage} path="/bradesco" /> 
        <Route component={Parceiro} path="/parceiro" />
        <Route component={OptIn} path="/oferta" />
        <Route component={FAQ} path="/faq" />
        <Route exact path="/venderconsorcio">
          <Redirect to="/" />
        </Route>
        <Route exact path="/venderconsorcio-v1">
          <Redirect to="/" />
        </Route>
        
        <Route component={SucessoOferta} path="/sucesso-oferta" />
        <Route component={SucessoVender} path="/vc-sucesso-v1" />
        <Route component={SucessoVenderV2} path="/vc-sucesso" />
        <Route component={NaoAtendeVender} path="/nao-atendida" />
        <Route component={RejeitouOferta} path="/rejeitou-oferta" />
        <Route component={V4OnboardingStart} path="/onboarding/inicio" />
        <Route component={V4OnboardingSelectAdmininstradora} path="/onboarding/step2" />
        <Route component={V4OnboardingSelectTypePerson} path="/onboarding/step3" />
        <Route component={V4OnboardingDocuments} path="/onboarding/step4" />
        <Route component={V4OnboardingSucesso} path="/onboarding/sucesso" />

        <Route exact path="/consorciogm">
          <Redirect to="/chevrolet" />
        </Route>
        <Route
          path="/health-check"
          exact
          component={() => <h3>Hey ConCash, version {pack.version}!!!</h3>}
        />
        <Route component={SobreAConCash} path="/conac2023/Sobre_Concash" />
        <Route path="*" component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default RoutesWeb;
