import styled from "styled-components";

export const V4DepoimentosContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 1025px) {
        align-items: center;
        padding: 24px;
        isolation: isolate;
        
        width: 100%;
        min-width: 192px;
        max-width: 624px;
        height: 188px;
        
        background: #FFFFFF;
        box-shadow: 0px 1px 8px rgba(57, 68, 86, 0.08), 0px 2px 12px rgba(57, 68, 86, 0.12);
        border-radius: 16px; 
    }
    @media (max-width: 1024px) {

        box-sizing: border-box;

        flex-direction: column;
        align-items: center;
        padding: 0px;
        isolation: isolate;

        width: 328px;
        min-width: 192px;
        max-width: 624px;
        height: 211px;

        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 4px;


        align-items: center;
        padding: 24px;
        isolation: isolate;
        
        width: 100%;

        height: 211px;
        
        background: #FFFFFF;
        box-shadow: 0px 1px 8px rgba(57, 68, 86, 0.08), 0px 2px 12px rgba(57, 68, 86, 0.12);
        border-radius: 16px; 
    }
    margin: 40px 0;
`;

