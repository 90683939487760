import styled from "styled-components";

export const V4HeaderContainer = styled.header`
  display: flex;
  flex: 1;
  z-index: 9999;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  height: 72px;
  margin-bottom: 72px;

  
  @media (min-width: 1024px) {
    padding: 0 60px;
  }
  
  @media (max-width: 1023px) {
    padding: 24px;
  }

`;