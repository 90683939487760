import React from "react";
import BlocoFAQ from "../../components/BlocoFAQ";
import Header from "../../components/shared/Header";
import { V4Header } from "../../components/V4";

const FAQ: React.FC = () => {
  return (
    <>
      <V4Header />
      <div>
        <BlocoFAQ />
      </div>
    </>
  );
};

export default FAQ;
