import React, { useEffect } from "react";
import RoutesWeb from "./routes";
import "./input.css";
import WebFont from "webfontloader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { persistor, store } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const App: any = () => {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto:100,300,400,500,700,900", "Open Sans"],
      },
    });
  }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <RoutesWeb />
          </HelmetProvider>
        </PersistGate >
      </Provider>
    </>
  );
}

export default App;
