import React, { useState } from "react";
import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";

import { AboutContainer, AboutContainerMobile } from "./styles";

import BusinessImg from "../../assets/images/business-2.png";
import Helmet from "react-helmet";
import { V4Footer, V4Header } from "../../components/V4";

const About: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  return (
    <>
      <Helmet title="Sobre Nós | Concash" />
      <Helmet>
        <meta
          name="descripton"
          content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
        />
      </Helmet>
      <V4Header />
      <div style={{ backgroundColor: "#fff", overflow: "hidden" }}>
        {!isMobile ? (
          <AboutContainer style={{ paddingBottom: 50 }}>
            <div className="title-box">
              <span className="title">
                O melhor lugar para você vender seu consórcio
              </span>
              <span className="subtitle">
                Uma empresa do BTG Pactual
              </span>
            </div>
            <div className="content">
              <img loading="lazy" src={BusinessImg} alt="business" />
            </div>
            <div className="text-box">
              <span className="text">
                A Concash surgiu para promover alternativas seguras aos consorciados com cotas canceladas ou mesmo ativas - 
                mas que não querem continuar com o produto - e atua fortemente para a transformação dos mercados primário e 
                secundário de consórcios, através de soluções que unem tecnologia, inovação, excelência no atendimento e um 
                time altamente capacitado. <br/><br/>

                Se por algum motivo você precisou desistir do seu consórcio, aqui você tem a oportunidade de recuperar o seu 
                dinheiro em um processo rápido, seguro, 100% digital e totalmente validado pela sua administradora. A satisfação 
                dos clientes é o nosso foco, por isso temos sempre as melhores ofertas e o menor prazo do mercado, promovendo uma 
                experiência única aos consorciados.<br/><br/>

                Fundada em 2020 pelo Grupo DNR, grupo que há 20 anos oferta serviços e soluções aos maiores bancos e instituições 
                financeiras do país, e pela 2TM Group, a Concash já ajudou mais de 50 mil famílias a recuperar o dinheiro de seu 
                consórcio e negociou mais de R$ 500 milhões em créditos.<br/><br/>

                Em 2023 foi adquirida pelo BTG Pactual, maior banco de investimentos da América Latina. Esse movimento trouxe ainda 
                mais solidez à operação, abrindo novas frentes de negócios com as administradoras e mais alternativas para atender 
                os consumidores que, por um motivo ou outro, precisaram desistir das suas cotas de consórcio.
              </span>
            </div>
          </AboutContainer>
        ) : (
          <AboutContainerMobile>
            <div className="title-box">
            <span className="title">
                O melhor lugar para você vender seu consórcio
              </span>
              <span className="subtitle">
                Uma empresa do BTG Pactual
              </span>
            </div>
            <div className="content">
              <img
                loading="lazy"
                src={BusinessImg}
                width={330}
                height={160}
                alt="business"
              />
            </div>
            <div className="text-box">
              <span className="text">
                A Concash surgiu para promover alternativas seguras aos consorciados com cotas canceladas ou mesmo ativas - 
                mas que não querem continuar com o produto - e atua fortemente para a transformação dos mercados primário e 
                secundário de consórcios, através de soluções que unem tecnologia, inovação, excelência no atendimento e um 
                time altamente capacitado. <br/><br/>

                Se por algum motivo você precisou desistir do seu consórcio, aqui você tem a oportunidade de recuperar o seu 
                dinheiro em um processo rápido, seguro, 100% digital e totalmente validado pela sua administradora. A satisfação 
                dos clientes é o nosso foco, por isso temos sempre as melhores ofertas e o menor prazo do mercado, promovendo uma 
                experiência única aos consorciados.<br/><br/>

                Fundada em 2020 pelo Grupo DNR, grupo que há 20 anos oferta serviços e soluções aos maiores bancos e instituições 
                financeiras do país, e pela 2TM Group, a Concash já ajudou mais de 50 mil famílias a recuperar o dinheiro de seu 
                consórcio e negociou mais de R$ 500 milhões em créditos.<br/><br/>

                Em 2023 foi adquirida pelo BTG Pactual, maior banco de investimentos da América Latina. Esse movimento trouxe ainda 
                mais solidez à operação, abrindo novas frentes de negócios com as administradoras e mais alternativas para atender 
                os consumidores que, por um motivo ou outro, precisaram desistir das suas cotas de consórcio.
              </span>
            </div>
          </AboutContainerMobile>
        )}
        <V4Footer />
      </div>
    </>
  );
};

export default About;
