import { Backdrop } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ContainerCookiesDeskop, ContainerCookiesMobile } from "./styles";
import { V3Button } from "../../styles/themes/components/button";

// import { Container } from './styles';

const Cookies: React.FC<any> = ({ setCookies }: any) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  return (
    <>
      {!isMobile ? (
        <Backdrop open style={{ zIndex: 1000 }}>
          <ContainerCookiesDeskop>
            <span className="text">
              Nós armazenamos dados temporariamente para melhorar a sua
              experiência de navegação e recomendar conteúdo do seu interesse.
              Ao continuar navegando, você concorda com a nossa{" "}
              <a
                href={require("../../assets/termo/Politica_Privacidade.pdf")}
                target="_blank"
                style={{
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "#000",
                }} rel="noreferrer"
              >
                política de privacidade
              </a>
              .
            </span>
            <V3Button
              estilo="primary"
              text="Continuar"
              onClick={() => {
                setCookies(true);
                localStorage.setItem("@cookies-concash", "aceito");
              }}
              width={110}
              height={45}
            />
          </ContainerCookiesDeskop>
        </Backdrop>
      ) : (
        <>
          <Backdrop open style={{ zIndex: 1000 }}>
            <ContainerCookiesMobile>
              <span className="text">
                Nós armazenamos dados temporariamente para melhorar a sua
                experiência de navegação e recomendar conteúdo do seu interesse.
                Ao continuar navegando, você concorda com a nossa{" "}
                <a
                  href={require("../../assets/termo/Politica_Privacidade.pdf")}
                  target="_blank"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "none",
                    color: "#000",
                  }} rel="noreferrer"
                >
                  política de privacidade
                </a>
                .
              </span>
              <div className="button-box-left">
                <V3Button
                  estilo="primary"
                  text="Continuar"
                  onClick={() => {
                    setCookies(true);
                    localStorage.setItem("@cookies-concash", "aceito");
                  }}
                  width={110}
                  height={45}
                  
                />
              </div>
            </ContainerCookiesMobile>
          </Backdrop>
        </>
      )}
    </>
  );
};

export default Cookies;
