import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  name: "",
  administratorId: "",
  administratorName: "",
  document: "",
  email: "",
  phone: "",
  utmCampaign: "",
  utmContent: "",
  utmMedium: "",
  utmSource: "",
  utmTerm: "",
  contactAllowed: "",
  birthdayDate: "",
  leadId: "",
  personType: "",
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    startRegister(state, action) {
      const { name, email, phone } = action.payload;
      state.name = name;
      state.phone = phone;
      state.email = email;
      return state;
    },
    setLeadId: (state, action) => {
      const { leadId } = action.payload;
      state.leadId = leadId;
      return state;
    },
    updateAdministradora: (state, action) => {
      const { name, id } = action.payload;
      state.administratorId = id;
      state.administratorName = name;
      return state;
    },
    updatePersonType(state, {payload}){
      const {personType} = payload;
      state.personType = personType;
      return state;
    },
    clearState(state){
      state = initialState;
      return state;
    }
  },
});

export const { startRegister, setLeadId, updateAdministradora, updatePersonType, clearState } = registerSlice.actions;
export const registerReducer = registerSlice.reducer;