import React from 'react';
import { V4AdminstradoraSection, V4BlogPosts, V4Footer, V4Header } from '../../../components/V4';
import { V4Onboarding } from '../../../components/V4/commons.styled';
import { HeadContent, ImageOnboarding } from './onboarding.styles';
import HeadOnboarding from "../../../assets/V4/onboarding/head-hero.svg";
import SuccessMobile from "../../../assets/V4/onboarding/successmob.jpg";
import SuccessWeb from "../../../assets/V4/onboarding/successweb.jpg";
import SuccessIconMobile from "../../../assets/V4/onboarding/check-success-mob.svg";
import SuccessIconWeb from "../../../assets/V4/onboarding/check-success-web.svg";
import { V4ContactTitle, V4TextNameTestimonial, V4TextRegular } from '../../../components/V4/commons/Tipography';
import { V4ButtonSubmit } from '../../../components/V4/commons/Button';
import { useDispatch } from 'react-redux';
import { clearState } from '../../../store/register/register.slice';
import { Helmet } from 'react-helmet-async';

const V4OnboardingSucesso: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(clearState());
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
          <Helmet title="Onboarding | Concash" />
          <Helmet>
            <meta
              name="descripton"
              content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
            />
          </Helmet>
      <V4Header />
      <V4Onboarding>
        {width > 1024 ? (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', width: '50%', flexDirection: 'column', padding: "32px 0", margin: "0 24px" }}>
              <div>
                <img src={SuccessIconWeb} alt="succ" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 34 }}>
                <div>
                  <V4ContactTitle>Formulário recebido!</V4ContactTitle>
                </div>
                <div>
                  <V4TextRegular>Nossa equipe já está analisando seus dados. Em breve, entraremos em contato com uma proposta personalizada para seu consórcio. </V4TextRegular>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: 80, width: '100%', flex: 1 }}>
                <V4ButtonSubmit text='Ir para a página inicial' type='LINK' color='#000' buttoncolor='transparent' border="1px solid #000" height={56} link="/" width={257} />
              </div>
            </div>
            <div style={{ display: 'flex', width: '50%', }}>
              <img src={SuccessWeb} />
            </div>
          </div>

        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <img src={SuccessMobile} style={{ width: '100vw' }} />

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: "32px 0", margin: "0 24px" }}>
              <div>
                <img src={SuccessIconMobile} alt="succ" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 34 }}>
                <div>
                  <V4ContactTitle>Formulário recebido!</V4ContactTitle>
                </div>
                <div>
                  <V4TextRegular>Nossa equipe já está analisando seus dados. Em breve, entraremos em contato com uma proposta personalizada para seu consórcio. </V4TextRegular>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: 34, width: '100%', flex: 1 }}>
                <V4ButtonSubmit text='Ir para a página inicial' type='LINK' color='#000' buttoncolor='transparent' border="1px solid #000" height={48} link="/" width="100%" />
              </div>
            </div>
          </div>

        )}
        <V4BlogPosts />

        <V4Footer />
      </V4Onboarding>
    </>
  )
};

export default V4OnboardingSucesso;