import React, { useState } from "react";
import {
  V4AdminstradoraSection,
  V4Footer,
  V4Header,
} from "../../../components/V4";
import { V4Onboarding } from "../../../components/V4/commons.styled";
import { HeadContent, ImageOnboarding } from "./onboarding.styles";
import HeadOnboarding from "../../../assets/V4/onboarding/head-hero.svg";
import Woman from "../../../assets/V4/onboarding/woman.svg";
import {
  V4TextMediumBold,
  V4TextRegular,
  V4Title,
} from "../../../components/V4/commons/Tipography";
import { V4OnboardingAdminstradoraSection } from "../../../components/V4/AdminstradoraSection";
import {
  V4ButtonSimple,
  V4ButtonSimpleWeb,
  V4ButtonSubmit,
} from "../../../components/V4/commons/Button";
import IconPF from "../../../assets/V4/icons/pf.svg";
import IconPJ from "../../../assets/V4/icons/pj.svg";
import { useDispatch, useSelector } from "react-redux";
import { V4Input } from "../../../components/V4/commons/Input";
import { v3FormataCNPJ, v3MaskCPF } from "../../../styles/themes/utils/format";
import { fillCPF } from "../../../utils/formaters";
import { validateDate } from "../../../utils/validate";
import api from "../../../services/api";
import { V4HeadOnboarding } from "../../../components/V4/commons/HeadOnboarding";
import { Helmet } from "react-helmet-async";

// import { Container } from './styles';

const V4OnboardingDocuments: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [document, setDocument] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthDateErr, setBirthDateErr] = useState(false);

  const { register } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  async function handleChangeCPFCNPJ(doc: string) {
    if (register.personType === "PJ") {
      setDocument(doc && v3FormataCNPJ(doc));
    } else {
      setDocument(doc && v3MaskCPF(doc));
    }
  }

  async function handleChangeBirthDate(doc: string) {
    let value = doc.replace(/\D/g, "");
    if (value.length > 8) value = value.slice(0, 8);

    const formattedValue = value
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})$/, "$1");

    const valid = validateDate(formattedValue);
    setBirthDateErr(!valid);
    setBirthDate(formattedValue);
  }

  async function handleSendForm() {
    const email = register?.email;
    const leadId = register?.leadId;
    const phone = register?.phone;
    const name = register?.name;
    const origin = register?.origin;
    const administratorId = register?.administratorId;
    const administratorName = register?.administratorName;
    let formData = new FormData();
    formData.append("birthdayDate", birthDate);
    formData.append("leadId", leadId);
    formData.append("document", document.replace(/[^0-9]/g, ""));
    formData.append("name", name);
    formData.append("administratorId", administratorId);
    if (phone && email && name) {
      const { data } = await api.patch("/api/campanha-lead/1.0/lead", formData);
      return (window.location.href = "/onboarding/sucesso");
    }
  }

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
          <Helmet title="Onboarding | Concash" />
          <Helmet>
            <meta
              name="descripton"
              content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
            />
          </Helmet>
      <V4Header />
      <V4Onboarding>
        <V4HeadOnboarding breadcrumb="Passo 4: Quem é o titular?" />
        <div
          style={{
            display: "flex",
            padding: width > 1024 ? "40px 60px" : "24px",
          }}
        >
          <V4Title>Quem é o titular?</V4Title>
        </div>
        {width > 1024 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "40px 60px",
              gap: 30,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 24 }}>
              <V4Input
                placeholder={register?.personType === "PJ" ? "CNPJ" : "CPF"}
                onchange={(e: any) => handleChangeCPFCNPJ(e.target.value)}
                value={document}
                width={350}
              />
              <V4Input
                placeholder={
                  register?.personType === "PJ"
                    ? "Data de Fundação"
                    : "Data de Nascimento"
                }
                onchange={(e: any) => handleChangeBirthDate(e.target.value)}
                value={birthDate}
                width={350}
                error={birthDateErr}
              />
            </div>
            <V4ButtonSubmit
              text="Enviar ao nosso time comercial >"
              disabled={!document || !birthDate || birthDateErr}
              type="BUTTON"
              height={48}
              width={280}
              onclick={handleSendForm}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              gap: 24,
            }}
          >
            <V4Input
              placeholder={register?.personType === "PJ" ? "CNPJ" : "CPF"}
              onchange={(e: any) => handleChangeCPFCNPJ(e.target.value)}
              value={document}
            />
            <V4Input
              placeholder={
                register?.personType === "PJ"
                  ? "Data de Fundação"
                  : "Data de Nascimento"
              }
              onchange={(e: any) => handleChangeBirthDate(e.target.value)}
              value={birthDate}
              error={birthDateErr}
            />
            <V4ButtonSubmit
              disabled={!document || !birthDate || birthDateErr}
              text="Enviar ao nosso time comercial >"
              type="BUTTON"
              onclick={handleSendForm}
            />
          </div>
        )}
      </V4Onboarding>
      <V4Footer />
    </>
  );
};

export default V4OnboardingDocuments;
