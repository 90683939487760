import React from 'react';
import styled from 'styled-components';
import { V4TextMediumBold, V4TextRegular, V4TextSmall } from '../../commons/Tipography';

import N1 from "../../../../assets/V4/numbers/1.svg";
import N2 from "../../../../assets/V4/numbers/2.svg";
import N3 from "../../../../assets/V4/numbers/3.svg";
import N4 from "../../../../assets/V4/numbers/4.svg";

const ContainerCard = styled.div`
    display: flex;
    box-sizing: border-box;
    @media (max-width: 1024px) {
        width: 256px;
        height: 58px;
        flex-direction: row;
    }
    @media (min-width: 1025px) {
        height: 141px;
        flex-direction: column;
    }
`;

const BoxImage = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 48px;
    height: 100%;
    justify-content: center;
    align-items: center;
`;
const BoxText = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    @media (max-width: 1024px) {
        margin-left: 10px;
    }
`;

const payloadCardNumbers: any = {
    "1": {
        title: "+54 mil",
        icon: <img src={N1} alt='1' />,
        text: "famílias atendidas"
    },
    "2": {
        title: "86%",
        icon: <img src={N2} alt='2' />,
        text: "propostas aceitas"
    },
    "3": {
        title: "+127 mil",
        icon: <img src={N3} alt='3' />,
        text: "cotas negociadas"
    },
    "4": {
        title: "80 milhões",
        icon: <img src={N4} alt='4' />,
        text: "negócios realizados"
    },
}

interface V4NumbersCardProps {
    type: "1" | "2" | "3" | "4";
}

export const V4NumbersCard: React.FC<V4NumbersCardProps> = ({type}) => {
  return(
    <>
        <ContainerCard>
            <BoxImage>{payloadCardNumbers[type]?.icon}</BoxImage>
            <BoxText>
                <V4TextMediumBold>{payloadCardNumbers[type]?.title}</V4TextMediumBold>
                <V4TextSmall>{payloadCardNumbers[type]?.text}</V4TextSmall>
            </BoxText>
        </ContainerCard>
    </>
  )
};