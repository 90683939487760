import React, { useState } from 'react';
import { V4Content } from '../../components/V4/commons.styled';
import "../../assets/V4/css/orquestra.css";
import { V4HeroLandingSection } from '../../components/V4/HeroSectionLanding';
import {Helmet} from "react-helmet-async";
import { V4AdminstradoraSection, V4ApprovesSection, V4BlogPosts, V4ContactSection, V4Footer, V4Header, V4HeroSection, V4NumbersSection, V4TopicsSection } from '../../components/V4';


const V4LandingAdmPage: React.FC = () => {

    return (
        <>
              <Helmet title="Venda seu Consórcio | Concash" />
              <Helmet>
                <meta
                  name="descripton"
                  content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
                />
              </Helmet>
            <V4Header />
            <V4Content>
                <V4HeroLandingSection />
                <V4TopicsSection />
                <V4NumbersSection />
                <V4ApprovesSection />
                <V4ContactSection />
                <V4BlogPosts />
                <V4Footer />
            </V4Content>
        </>
    );
}

export default V4LandingAdmPage;
