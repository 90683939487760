import React from "react";
import LogoMob from "../../../assets/images/logo-negative.png";
import { ContainerFooter } from "./footer.styled";
import { V4TextFooter, V4TextFooterSmall } from "../commons/Tipography";
import ReclameAqui from "../../../assets/V4/ver.avif";
import Facebook from "../../../assets/V4/redes-sociais/facebook.svg";
import Instagram from "../../../assets/V4/redes-sociais/instagram.svg";
import LinkeIn from "../../../assets/V4/redes-sociais/linkedin.svg";

export const V4Footer: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ContainerFooter>
      {width <= 1024 ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 28 }}>
          <div>
            <img
              src={LogoMob}
              alt="logo"
              style={{ filter: "brightness(0) invert(1)", width: 200 }}
            />
          </div>
          <div>
            <V4TextFooter>Somos uma empresa do grupo BTG Pactual</V4TextFooter>
          </div>
          <div>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                marginTop: 10,
                fontSize: 12,
                alignItems: 'center',
                color: "#FFF",
              }}
            >
              <li>
                <a
                  href="https://blog.concash.com.br"
                  style={{
                    textDecoration: "none",
                    color: "#FFF",
                  }}
                >
                  <V4TextFooter  style={{ fontSize: 12 }}>Blog</V4TextFooter>
                </a>
              </li>
              |
              <li>
                <a
                  href="/sobre"
                  style={{
                    textDecoration: "none",
                    color: "#FFF",
                  }}
                >
                  <V4TextFooter  style={{ fontSize: 12 }}>Sobre Nós</V4TextFooter>
                </a>
              </li>
              |
              <li>
                <a
                  href="/faq"
                  style={{
                    textDecoration: "none",
                    color: "#FFF",
                  }}
                >
                  <V4TextFooter  style={{ fontSize: 12 }}>Perguntas Frequentes</V4TextFooter>
                </a>
              </li>
              |
              <li>
                <a
                  href="https://parceiros.concash.com.br/parceiros-b2b"
                  style={{
                    textDecoration: "none",
                    color: "#FFF",
                  }}
                >
                  <V4TextFooter style={{ fontSize: 12 }}>Seja parceiro</V4TextFooter>
                </a>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 14,
              marginTop: 10,
            }}
          >
            <div style={{ display: "flex", gap: 24 }}>
              <img
                src={Facebook}
                alt="facebook"
                onClick={() =>
                  window.open("https://facebook.com/voceconcash", "_blank")
                }
              />
              <img
                src={Instagram}
                alt="instagram"
                onClick={() =>
                  window.open("https://instagram.com/voceconcash", "_blank")
                }
              />
              <img
                src={LinkeIn}
                alt="linkedin"
                onClick={() =>
                  window.open(
                    "https://linkedin.com/company/voceconcash",
                    "_blank"
                  )
                }
              />
            </div>
            <V4TextFooterSmall>
              Horário de atendimento: Segunda a sexta das 9h às 18h, Sábado e
              Domingo das 8h às 14h
            </V4TextFooterSmall>
            <a
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                style={{ textDecoration: "none", color: "#FFF" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <V4TextFooterSmall>
                  Termos e Política de Privacidade
                </V4TextFooterSmall>
              </a>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                marginTop: 10,
                gap: 8,
              }}
            >
              <V4TextFooterSmall>voce@concash.com.br</V4TextFooterSmall>
              <V4TextFooterSmall>(11) 4040-5686 (Whatsapp)</V4TextFooterSmall>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <img
              src={ReclameAqui}
              style={{ width: 195, height: 70 }}
              alt="reclame"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
            width: "100%",
          }}
        >
          <div>
            <img
              src={LogoMob}
              alt="logo"
              style={{ width: 88, filter: "brightness(0) invert(1)" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flex: 1, width: "100%" }}>
              <V4TextFooter>
                Somos uma empresa do grupo BTG Pactual
              </V4TextFooter>
            </div>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                gap: 16,
              }}
            >
              <li>
                <a
                  href="https://blog.concash.com.br"
                  style={{ textDecoration: "none", color: "#FFF" }}
                >
                  <V4TextFooter>Blog</V4TextFooter>
                </a>
              </li>
              <li>
                <a
                  href="/sobre"
                  style={{ textDecoration: "none", color: "#FFF" }}
                >
                  <V4TextFooter>Sobre Nós</V4TextFooter>
                </a>
              </li>
              <li>
                <a
                  href="/faq"
                  style={{ textDecoration: "none", color: "#FFF" }}
                >
                  <V4TextFooter>Perguntas Frequentes</V4TextFooter>
                </a>
              </li>
              <li>
                <a
                  href="https://parceiros.concash.com.br/parceiros-b2b"
                  style={{ textDecoration: "none", color: "#FFF" }}
                >
                  <V4TextFooter>Seja parceiro</V4TextFooter>
                </a>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid #FFF",
              paddingTop: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              <div style={{ display: "flex", gap: 24 }}>
                <img
                  src={Facebook}
                  alt="facebook"
                  onClick={() =>
                    window.open("https://facebook.com/voceconcash", "_blank")
                  }
                />
                <img
                  src={Instagram}
                  alt="instagram"
                  onClick={() =>
                    window.open("https://instagram.com/voceconcash", "_blank")
                  }
                />
                <img
                  src={LinkeIn}
                  alt="linkedin"
                  onClick={() =>
                    window.open(
                      "https://linkedin.com/company/voceconcash",
                      "_blank"
                    )
                  }
                />
              </div>
              <V4TextFooterSmall>
                Horário de atendimento: Segunda a sexta das 9h às 18h, Sábado e
                Domingo das 8h às 14h
              </V4TextFooterSmall>
              <a
                href={require("../../../assets/termo/Politica_Privacidade.pdf")}
                style={{ textDecoration: "none", color: "#FFF" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <V4TextFooterSmall>
                  Termos e Política de Privacidade
                </V4TextFooterSmall>
              </a>
              <div style={{ display: "flex", gap: 20 }}>
                <V4TextFooterSmall>voce@concash.com.br</V4TextFooterSmall>
                <V4TextFooterSmall>(11) 4040-5686 (Whatsapp)</V4TextFooterSmall>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img
                src={ReclameAqui}
                style={{ width: 195, height: 70 }}
                alt="reclame"
              />
            </div>
          </div>
        </div>
      )}
    </ContainerFooter>
  );
};
