import React from 'react';
import SantanderWeb from "../../../../assets/V4/administradoras/santander-web.svg";
import SantanderMobile from "../../../../assets/V4/administradoras/santander-mob.svg";
import PortoWeb from "../../../../assets/V4/administradoras/porto-web.svg";
import PortoMobile from "../../../../assets/V4/administradoras/porto-mob.svg";
import VWMobile from "../../../../assets/V4/administradoras/vw-mob.svg";
import VWWeb from "../../../../assets/V4/administradoras/vw-web.svg";
import CanopusWeb from "../../../../assets/V4/administradoras/canopus-web.svg";
import CanopusMobile from "../../../../assets/V4/administradoras/canopus-mob.svg";
import RandonWeb from "../../../../assets/V4/administradoras/randon-web.svg";
import RandonMobile from "../../../../assets/V4/administradoras/randon-mob.svg";
import DAFMobile from "../../../../assets/V4/administradoras/DAF-mob.svg";
import DAFWeb from "../../../../assets/V4/administradoras/DAF-web.svg";
import BradescoWeb from "../../../../assets/V4/administradoras/bradesco-web.svg";
import BradescoMobile from "../../../../assets/V4/administradoras/bradesco-mob.svg";
import OutrasMobile from "../../../../assets/V4/administradoras/outras-mob.svg";
import OutrasWeb from "../../../../assets/V4/administradoras/outras-mob.svg";


import { BoxCardOnboardingStyled, BoxCardStyled, BoxImageStyled, CellTextCardStyled } from './cardadm.styled';
import { V4TextRegular, V4TextSmall } from '../../commons/Tipography';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdministradora } from '../../../../store/register/register.slice';
import { administradorasList } from '../../../../pages/VenderConsorcio/steps/data.content';

interface CardAdministradoraProps {
    administradora: string;
    idAdm?: any;
}


export const CardAdministradora: React.FC<CardAdministradoraProps> = ({ administradora }) => {
    const [width, setWidth] = React.useState(window.innerWidth);


    const payloadAdministradoras: any = {
        'santander': {
            logo: width > 1024 ? <img src={SantanderWeb} /> : <img src={SantanderMobile} />,
            link: '/santander',
            title: 'Santander'
        },
        'porto': {
            logo: width > 1024 ? <img src={PortoWeb} /> : <img src={PortoMobile} />,
            link: '/porto',
            title: 'Porto'
        },
        'vw': {
            logo: width > 1024 ? <img src={VWWeb} /> : <img src={VWMobile} />,
            link: '/volks',
            title: 'Volkswagen'
        },
        'canopus': {
            logo: width > 1024 ? <img src={CanopusWeb} /> : <img src={CanopusMobile} />,
            link: '/canopus',
            title: 'Canopus'
        },
        'randon': {
            logo: width > 1024 ? <img src={RandonWeb} /> : <img src={RandonMobile} />,
            link: '/randon',
            title: 'Randon'
        },
        'daf': {
            logo: width > 1024 ? <img src={DAFWeb} /> : <img src={DAFMobile} />,
            link: '/daf',
            title: 'DAF'
        },
        'bradesco': {
            logo: width > 1024 ? <img src={BradescoWeb} /> : <img src={BradescoMobile} />,
            link: '/bradesco',
            title: 'Bradesco'
        },
        'outras': {
            logo: width > 1024 ? <img src={OutrasWeb} /> : <img src={OutrasMobile} />,
            link: '/',
            title: 'Outras'
        },
    }

    React.useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return (
        <>
            <BoxCardStyled href={payloadAdministradoras[administradora]?.link}>
                <BoxImageStyled>
                {payloadAdministradoras[administradora]?.logo}
                </BoxImageStyled>
                <CellTextCardStyled>
                <V4TextRegular>{payloadAdministradoras[administradora]?.title}</V4TextRegular>
                {width > 1024 && <V4TextSmall>{administradora !== 'outras' ? 'Selecionar >' : 'Acessar lista completa'}</V4TextSmall>}
                </CellTextCardStyled>
            </BoxCardStyled>
        </>
    )
};
export const CardOnboardingAdministradora: React.FC<CardAdministradoraProps> = ({ administradora, idAdm }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const baseLocal = useSelector((state: any) => state.register);
    const dispatch = useDispatch();

    const payloadAdministradoras: any = {
        'santander': {
            logo: width > 1024 ? <img src={SantanderWeb} /> : <img src={SantanderMobile} />,
            link: '/santander',
            title: 'Santander'
        },
        'porto': {
            logo: width > 1024 ? <img src={PortoWeb} /> : <img src={PortoMobile} />,
            link: '/porto',
            title: 'Porto'
        },
        'vw': {
            logo: width > 1024 ? <img src={VWWeb} /> : <img src={VWMobile} />,
            link: '/volks',
            title: 'Volkswagen'
        },
        'canopus': {
            logo: width > 1024 ? <img src={CanopusWeb} /> : <img src={CanopusMobile} />,
            link: '/canopus',
            title: 'Canopus'
        },
        'randon': {
            logo: width > 1024 ? <img src={RandonWeb} /> : <img src={RandonMobile} />,
            link: '/randon',
            title: 'Randon'
        },
        'daf': {
            logo: width > 1024 ? <img src={DAFWeb} /> : <img src={DAFMobile} />,
            link: '/daf',
            title: 'DAF'
        },
        'bradesco': {
            logo: width > 1024 ? <img src={BradescoWeb} /> : <img src={BradescoMobile} />,
            link: '/bradesco',
            title: 'Bradesco'
        },
        'outras': {
            logo: width > 1024 ? <img src={OutrasWeb} /> : <img src={OutrasMobile} />,
            link: '/',
            title: 'Outras'
        },
    }

    async function handleSelectAdministradora(adm: string){
        const admSearched = administradorasList.find((v) => v.id == idAdm);
        dispatch(updateAdministradora({id:admSearched?.id, name: admSearched?.label }))
        return await window.open("/onboarding/step3");
    }

    React.useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return (
        <>
            <BoxCardOnboardingStyled  onClick={() => handleSelectAdministradora(administradora)}>
                <BoxImageStyled>
                {payloadAdministradoras[administradora]?.logo}
                </BoxImageStyled>
                <CellTextCardStyled>
                <V4TextRegular>{payloadAdministradoras[administradora]?.title}</V4TextRegular>
                {width > 1024 && <V4TextSmall>{administradora !== 'outras' ? 'Selecionar >' : 'Acessar lista completa'}</V4TextSmall>}
                </CellTextCardStyled>
            </BoxCardOnboardingStyled>
        </>
    )
};

export const CardOnboardingAdministradoraOutros = ({onclick}: any) => {
  const [width, setWidth] = React.useState(window.innerWidth);


  return (
    <>
        <BoxCardOnboardingStyled  onClick={onclick}>
            <BoxImageStyled><img src={OutrasWeb} /></BoxImageStyled>
            <CellTextCardStyled>
            <V4TextRegular>Outras</V4TextRegular>
            {width > 1024 && <V4TextSmall>Acessar lista completa</V4TextSmall>}
            </CellTextCardStyled>
        </BoxCardOnboardingStyled>
    </>
)
}