import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { handleGetBlogPosts } from "./helper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

interface ArticleProps {
  title?: string;
  link?: string;
  image?: any;
}

const V4BlogPosts: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [news, setNews] = useState([]);

  const Container = styled.div`
    display: flex;
    flex: 1;

    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    max-width: 100vw;
  `;

  const ContainerMobile = styled.div`
    display: flex;
    z-index: 900;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    box-sizing: border-box;
  `;

  async function getData() {
    const dados: any = await handleGetBlogPosts();
    setNews(dados);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isMobile ? (
        <ContainerMobile>
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            slidesPerView={1}
            loop
            autoplay={{ delay: 5000 }}
            pagination={{ clickable: true }}
          >
            {news &&
              news.map((item: any) => (
                <SwiperSlide>
                  <ArticleMobile
                    image={item.image}
                    link={item.link}
                    title={item.text}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </ContainerMobile>
      ) : (
        <Container>
          {news &&
            news.map((item: any) => (
              <Article image={item.image} link={item.link} title={item.text} />
            ))}
        </Container>
      )}
    </>
  );
};

const Article: React.FC<any> = ({ image, title, link }: ArticleProps) => {
  const Container = styled.div`
    display: flex;
    flex: 3;
    position: relative;
    margin: 20px 10px;
    flex-direction: column;
    border-radius: 20px;
    max-width: 312px;
    height: 346px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 40px #0000001a;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
  `;

  const BoxImg = styled.div`
    display: flex;
    width: 312px;
    max-width: 312px;
    height: 180px;
    border-radius: 20px 20px 0 0;
    background: transparent url(${image}) 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: center;
  `;

  const BoxText = styled.div`
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-between;
    padding: 24px;
  `;
  const Text = styled.span`
    text-align: left;
    font: normal normal normal 15px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `;

  const TimeInfo = styled.div`
    display: flex;
    gap: 12px;
    letter-spacing: 0px;

    text-decoration: none;
  `;

  const TextTimeInfo = styled.span`
    font-family: "Moderat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

    color: rgba(0, 0, 0, 0.5);
  `;

  return (
    <>
      <Container onClick={() => window.open(link, "_blank")}>
        <BoxImg />
        <BoxText>
          <Text>{title}</Text>
          <TimeInfo>
            <div>
              <TextTimeInfo>Há 4 horas</TextTimeInfo>
            </div>
            <div>
              <TextTimeInfo> 13 minutos de leitura</TextTimeInfo>
            </div>
          </TimeInfo>
        </BoxText>
      </Container>
    </>
  );
};
const ArticleMobile: React.FC<any> = ({ image, title, link }: ArticleProps) => {
  const Container = styled.div`
    display: flex;
    position: relative;
  width: 100%;
    flex-direction: column;
    border-radius: 20px;
    height: 461px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 40px #0000001a;
    border-radius: 20px;
    opacity: 1;
    margin-bottom: 30px;
    padding: 20px;
  `;

  const BoxImgMobile = styled.div`
    display: flex;
    width: 100%;
    height: 290px;
    border-radius: 20px 20px 0 0;
    background: transparent url(${image}) 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: center;
  `;
  const BoxText = styled.div`
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-between;
    padding: 24px;
  `;
  const Text = styled.span`
    text-align: left;
    font: normal normal normal 15px/23px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `;

  const TimeInfo = styled.div`
    display: flex;
    gap: 12px;
    letter-spacing: 0px;

    text-decoration: none;
  `;

  const TextTimeInfo = styled.span`
    font-family: "Moderat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

    color: rgba(0, 0, 0, 0.5);
  `;

  return (
    <>
      <Container onClick={() => window.open(link, "_blank")}>
        <BoxImgMobile />
        <BoxText>
          <Text>{title}</Text>
          <TimeInfo>
            <div>
              <TextTimeInfo>Há 4 horas</TextTimeInfo>
            </div>
            <div>
              <TextTimeInfo> 13 minutos de leitura</TextTimeInfo>
            </div>
          </TimeInfo>
        </BoxText>
      </Container>
    </>
  );
};

export default V4BlogPosts;
