import styled from "styled-components";

export const ContainerContact = styled.section`
    display: flex;
    background-color: #f5f5f6;

    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 64px 24px;
        gap: 40px;
    }

    @media (min-width: 1025px) {
        padding: 64px;
        gap: 64px;
    }
`;

export const BoxContact = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
        gap: 40px;
    }

    @media (min-width: 1025px) {
        gap: 64px;
    }
`;