import React, { useEffect, useState } from 'react';
import { V4Content } from '../../components/V4/commons.styled';
import "../../assets/V4/css/orquestra.css";
import { V4HeroLandingSection } from '../../components/V4/HeroSectionLanding';
import {Helmet} from "react-helmet-async";
import { V4AdminstradoraSection, V4ApprovesSection, V4BlogPosts, V4ContactSection, V4Footer, V4Header, V4HeroSection, V4NumbersSection, V4TopicsSection } from '../../components/V4';
import { V4HeroLandingOutrasSection } from '../../components/V4/HeroSectionLanding/outras';
import { useParams } from 'react-router-dom';
import outrasAdms from "../../assets/V4/admin.json";


const V4LandingOutrasAdmsPage: React.FC = () => {
  const [oAdm, setOAdm ] = useState("");
  const { id }: any = useParams();

  useEffect(() => {
    const adms: any = outrasAdms;
    const searched = adms.find((a: any) => a["ID"] == id);
    setOAdm(searched);
  }, [id]);

    return (
        <>
              <Helmet title="Venda seu Consórcio | Concash" />
              <Helmet>
                <meta
                  name="descripton"
                  content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
                />
              </Helmet>
            <V4Header />
            <V4Content>
                <V4HeroLandingOutrasSection administradora={oAdm} />
                <V4TopicsSection />
                <V4NumbersSection />
                <V4ApprovesSection />
                <V4ContactSection />
                <V4BlogPosts />
                <V4Footer />
            </V4Content>
        </>
    );
}

export default V4LandingOutrasAdmsPage;
