import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  iniciou: false,
  desempenho: false,
  necessarios: true,
  publicidade: false,
  estatistica: false
};

export const optoutSlice = createSlice({
  name: "optout",
  initialState,
  reducers: {
    changeCookie(state, action) {
      const { cookie, valor } = action.payload;
      state[cookie] = valor;
      return state;
    },
    acceptAllCookies(state) {
      state.iniciou = true;
      state.desempenho = true;
      state.necessarios = true;
      state.publicidade = true;
      state.estatistica = true;
      return state;
    },
    acceptRequiredCookies(state) {
      state.iniciou = true;
      state.necessarios = true;
      state.estatistica = true;
      return state;
    
    },
    showCookies(state) {
      state.iniciou = false;
      return state;
    },
    closeCookies(state) {
      state.iniciou = true;
      return state;
    },
    clearState(state){
      state = initialState;
      return state;
    }
  },
});

export const { changeCookie, clearState, acceptAllCookies, showCookies, closeCookies, acceptRequiredCookies } = optoutSlice.actions;
export const optoutReducer = optoutSlice.reducer;