import styled from "styled-components";
import { V4ButtonSubmit } from "../../../../components/V4/commons/Button";
import { MdArrowForward } from "react-icons/md";
import { V4TextSmall } from "../../../../components/V4/commons/Tipography";
import { useDispatch } from "react-redux";
import { updateAdministradora } from "../../../../store/register/register.slice";

export const Container = styled.div`
  display: flex;
  z-index: 9999;
  flex-direction: column;
  background-color: #fff;
  @media (min-width: 1025px) {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.16);
    width: 922px;
    height: 570px;
    padding: 20px;
    border-radius: 8px;
  }
  @media (max-width: 1024px) {

   position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.16);
    width: 100%;
    height: 80%;
    bottom: 0;
    border-radius: 8px 8px 0 0;
    padding: 10px;
  }
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AdmItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 56px;
  max-height: 56px;
  
`;

export const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-top: 30px;
  gap:8px;

`;

interface V4AdministradoraSelectItemProps {
  label: String;
  id: any;
}

export const V4AdministradoraSelectItem = ({
  label,
  id,
}: V4AdministradoraSelectItemProps) => {

  const dispatch = useDispatch();
  function handleSelect() {
    dispatch(updateAdministradora({id: id, name: label }));
    window.location.href = "/onboarding/step3";
  }
  return (
    <AdmItem>
      <V4TextSmall>{label}</V4TextSmall>
      <V4ButtonSubmit
        type="BUTTON"
        onclick={handleSelect}
        fontSize={12}
        text={"Selecionar >"}
        buttoncolor="transparent"
        color={"#000"}
        width={123}
        height={32}
        radius={4}
        border={"1px solid #53821B"}
      />
    </AdmItem>
  );
};
