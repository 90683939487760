import styled from "styled-components";

export const V4Title = styled.h1`
      @media (min-width: 1025px) {
                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 2.75rem;
                line-height: 130%;
                /* or 57px */
                font-feature-settings: 'pnum' on, 'lnum' on;

                /* on-color/emphasis/high */
                color: rgba(0, 0, 0, 0.96);
            
        }
        
        @media (max-width: 1024px) {

                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 125%;
                /* or 30px */
                font-feature-settings: 'pnum' on, 'lnum' on;

                /* on-color/emphasis/high */
                color: rgba(0, 0, 0, 0.96);
        }
`;
export const V4SubTitle = styled.h2`
      @media (min-width: 1025px) {
                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 2rem;
                line-height: 3.562rem;
                font-feature-settings: 'pnum' on, 'lnum' on;

                color: rgba(0, 0, 0, 0.96);
            
        }
        
        @media (max-width: 1024px) {

                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.875rem;
                font-feature-settings: 'pnum' on, 'lnum' on;

                /* on-color/emphasis/high */
                color: rgba(0, 0, 0, 0.96);
        }
`;

export const V4ContactTitle = styled.h3`
      @media (min-width: 1025px) {

                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 125%;
                font-feature-settings: 'pnum' on, 'lnum' on;

                color: rgba(0, 0, 0, 0.96);

        }
        
        @media (max-width: 1024px) {

                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 125%;
                font-feature-settings: 'pnum' on, 'lnum' on;

                color: rgba(0, 0, 0, 0.96);

        }
`;



export const V4TextRegular = styled.span`

        font-family: 'Moderat';
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #000;
        /* or 24px */
        font-feature-settings: 'pnum' on, 'lnum' on;

        @media (min-width: 1025px) {
                font-size: 18px;
        }
        @media (max-width: 1024px) {
                font-size: 16px;
        }
`;

export const V4TextMediumBold = styled.h3`

        font-family: 'Moderat';
        font-style: normal;
        font-weight: 700;
        
        color: #000;
        /* or 24px */
        font-feature-settings: 'pnum' on, 'lnum' on;

        @media (min-width: 1025px) {
                font-size: 44px;
                line-height: 66px;
        }
        @media (max-width: 1024px) {
                font-size: 34px;
                line-height: 54px;
        }
`;

export const V4TextButton = styled.span`

        font-family: 'Moderat';
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #FFF;
        /* or 24px */
        font-feature-settings: 'pnum' on, 'lnum' on;

        @media (min-width: 1025px) {
                font-size: 16px;
        }
        @media (max-width: 1024px) {
                font-size: 18px;
        }
`;

export const V4TextSmall = styled.span`
        font-family: 'Moderat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        display: flex;
        align-items: center;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: rgba(0, 0, 0, 0.96);

`;

export const V4TextNameTestimonial = styled.span`
        font-family: 'Moderat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: rgba(0, 0, 0, 0.8);

`;

export const V4TextOcupationTestimonial = styled.span`
        font-family: 'Moderat';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 125%;

        font-feature-settings: 'pnum' on, 'lnum' on;

        color: rgba(0, 0, 0, 0.64);

`;

export const V4TextFooter = styled.span`
        @media (max-width: 1024px) {
                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                font-feature-settings: 'pnum' on, 'lnum' on;

                color: rgba(255, 255, 255, 0.96);

        }
        @media (min-width: 1025px) {

                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 125%;
                font-feature-settings: 'pnum' on, 'lnum' on;

                color: rgba(255, 255, 255, 0.8);

        }
`;
export const V4TextFooterSmall = styled.span`
        @media (max-width: 1024px) {
                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                font-feature-settings: 'pnum' on, 'lnum' on;

                color: rgba(255, 255, 255, 0.96);

        }
        @media (min-width: 1025px) {

                font-family: 'Moderat';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 125%;
                font-feature-settings: 'pnum' on, 'lnum' on;

                color: rgba(255, 255, 255, 0.8);

        }
`;