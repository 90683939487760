import React, { useState } from "react";
import { BoxContact, ContainerContact } from "./contact.styled";
import ImageContactMan from "../../../assets/V4/duvida-graf.png";
import {
  V4ContactTitle,
  V4SubTitle,
  V4TextRegular,
  V4TextSmall,
} from "../commons/Tipography";
import { V4Input } from "../commons/Input";
import { V4ButtonSubmit } from "../commons/Button";
import { Checkbox } from "@mui/material";
import { V4Modal } from "../Modal";
import {
  setLeadId,
  startRegister,
} from "../../../store/register/register.slice";
import { v3CellphoneFormater } from "../../../styles/themes/utils/format";
import api from "../../../services/api";
import { v3ValidateEmail } from "../../../styles/themes/utils/validate";
import { useDispatch } from "react-redux";

export const V4ContactSection: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [accepted, setAccepted] = useState(false);

  function handleChangeEmail(t: string) {
    const text = t?.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleChangeName(n: string) {
    setName(n);
  }

  function handleChangePhone(t: string) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setPhoneErr(false);
      if (t.length > 15) {
        setPhone(phone);
      } else {
        setPhone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setPhoneErr(true);
      setPhone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setPhoneErr(true);
    } else {
      setPhoneErr(false);
    }
  }

  async function handleSendForm() {
    if (!name) {
      setErrorText("Favor informar o nome.");
      return setHasError(true);
    }
    if (!phone || phoneErr) {
      setErrorText("Favor informar o telefone corretamente.");
      return setHasError(true);
    }
    if (!email || emailErr) {
      setErrorText("Favor informar o telefone corretamente.");
      return setHasError(true);
    }

    let formData = new FormData();
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("name", name);
    formData.append("contactAllowed", "true");
    formData.append("origin", "Web Site Duvidas");
    if (phone && !phoneErr && !emailErr && name) {
      dispatch(startRegister({ name, phone, email }));
      const { data } = await api.post("/api/campanha-lead/1.0/lead", formData)
        .then;
      dispatch(setLeadId({ leadId: data?.id }));

      return (window.location.href = "/onboarding/step3");
    }
  }

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <V4Modal
        open={hasError}
        actionClose={() => setHasError(false)}
        title={"Atenção!"}
        text={errorText}
        type="error"
      />
      <V4Modal
        open={hasSuccess}
        actionClose={setHasSuccess}
        title={"Sucesso!"}
        text={successText}
        type="success"
      />
      <ContainerContact id="contact">
        {width > 1024 && (
          <img src={ImageContactMan} style={{ display: 'flex', borderRadius: 16, width: '50%', height: '50%' }} />
        )}
        <BoxContact>
          <div>
            <V4ContactTitle>
              Dúvidas sobre a venda de consórcio? Nós podemos te ajudar!
            </V4ContactTitle>
            <V4TextRegular>
              Nosso time vai entrar em contato com você. Confira os dados antes
              de enviar e aproveite a oportunidade.
            </V4TextRegular>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: width > 1024 ? 16 : 10,
            }}
          >
            <V4Input
              placeholder={"Digite seu nome"}
              onchange={(e: any) => handleChangeName(e.target.value)}
              value={name}
            />
            {width > 1024 && (
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  width: "100%",
                  marginBottom: 24,
                }}
              >
                <V4Input
                  placeholder={"Telefone"}
                  width="100%"
                  onchange={(e: any) => handleChangePhone(e.target.value)}
                  value={phone}
                  error={phoneErr}
                />
                <V4Input
                  placeholder={"E-mail"}
                  width="100%"
                  onchange={(e: any) => handleChangeEmail(e.target.value)}
                  value={email}
                  error={emailErr}
                />
              </div>
            )}
            {width <= 1024 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 26,
                  marginTop: 16,
                  marginBottom: 24,
                }}
              >
                <V4Input
                  placeholder={"Telefone"}
                  width="100%"
                  onchange={(e: any) => handleChangePhone(e.target.value)}
                  value={phone}
                  error={phoneErr}
                />
                <V4Input
                  placeholder={"E-mail"}
                  width="100%"
                  onchange={(e: any) => handleChangeEmail(e.target.value)}
                  value={email}
                  error={emailErr}
                />
              </div>
            )}
            <div
              style={{
                height: 24,
              }}
            />
            {width <= 1024 ? (
              <V4ButtonSubmit
                type="BUTTON"
                text="Enviar dados"
                radius={"30px"}
                onclick={handleSendForm}
              />
            ) : (
              <V4ButtonSubmit
                type="BUTTON"
                link={"#form"}
                text="Enviar dados"
                radius={"16px"}
                height={56}
                width={124}
                onclick={handleSendForm}
              />
            )}
                      <V4TextSmall style={{ color: "rgba(0,0,0,0.64)" }}>
                        Ao continuar, você concorda em compartilhar seus dados com a
                        Concash, de acordo com as leis de proteção de dados LGPD.
                      </V4TextSmall>
          </div>
        </BoxContact>
      </ContainerContact>
    </>
  );
};
