import React from 'react';
import styled from 'styled-components';
import Topic1 from "../../../../assets/V4/topics/1.svg";
import Topic2 from "../../../../assets/V4/topics/2.svg";
import Topic3 from "../../../../assets/V4/topics/3.svg";
import { V4TextRegular, V4TextSmall } from '../../commons/Tipography';

// Styles

const Container = styled.section`
    display: flex;
    width: 100%;
    margin-top: 16px;
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 24px;
    }
    @media (min-width: 1025px) {
        flex-direction: row;
        gap: 40px;
    }

`;
const Box = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (max-width: 1024px) {
    }
    @media (min-width: 1025px) {
    }

`;

interface V4ItemTopicProps {
  type: string;
}

const payloadItemTopic: any = {
  "1": {
    title: "Preencha os dados",
    icon: <img src={Topic1} alt="t1" />,
    text: "Preencha nosso formulário online com os dados da sua cota e descubra como é rápido e fácil avançar na venda em menos de 2 minutos."
  },
  "2": {
    title: "Receba a proposta",
    icon: <img src={Topic2} alt='t2' />,
    text: "Deixe com a gente! Nossa equipe vai analisar seus dados e preparar uma proposta sob medida. Sem compromisso, com total clareza e feita para você."
  },
  "3": {
    title: "Dinheiro na conta",
    icon: <img src={Topic3} alt="t3" />,
    text: "Com a aprovação da proposta, basta esperar pela transferência da cota e o depósito do valor diretamente na sua conta. Tudo rápido, fácil e seguro!"
  },
}

const V4ItemTopic: React.FC<V4ItemTopicProps> = ({ type }) => {
  return (
    <>
      <Box>
        <div>{payloadItemTopic[type]?.icon}</div>
        <V4TextRegular style={{ marginTop: 16, marginBottom: 8}}>{payloadItemTopic[type]?.title}</V4TextRegular>
        <V4TextSmall>{payloadItemTopic[type]?.text}</V4TextSmall>
      </Box>
    </>
  );
};
export const V4ItemTopics: React.FC<any> = () => {
  return (
    <>
      <Container>
      <V4ItemTopic type='1' />
      <V4ItemTopic type='2' />
      <V4ItemTopic type='3' />
      </Container>
    </>
  );
};