import styled from "styled-components";

export const ContainerCookiesDeskop = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  bottom: 0;
  left: 0px;
  max-height: 103px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #00000029;
  opacity: 1;
  padding: 30px 83px;
  gap: 79px;
  justify-content: space-between;

  .text {
    font: normal normal normal 15px/23px Roboto;
    line-heiht: 1;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
`;

export const ContainerCookiesMobile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0px;
  align-content: space-between;
  min-height: 187px;
  max-height: 247px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #00000029;
  padding: 30px;
  opacity: 1;

  .button-box {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .button-box-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .text {
    font: normal normal normal 15px/23px Roboto;
    line-heiht: 1;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
`;
