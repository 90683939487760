import "../../assets/V4/css/orquestra.css";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";
import Cookies from '../../components/Cookies';
import { useSelector } from 'react-redux';
import { V4Content } from "../../components/V4/commons.styled";
import { V4AdminstradoraSection , V4ApprovesSection, V4BlogPosts, V4ContactSection, V4Footer, V4Header, V4HeroSection, V4NumbersSection, V4TopicsSection } from "../../components/V4";

const V4Page = () => {
  const faqRef = useRef<HTMLDivElement>(null);
  const [parameters, setParameters] = useState<any>({});
  const [isBanrisul, setBanrisul] = useState(false);

  const [qName, setQName] = useState("");
  const [qDoc, setQDoc] = useState("");
  const [qPhone, setQPhone] = useState("");
  const [qEmail, setQEmail] = useState("");
  const [cookies, setCookies] = useState(
    !!localStorage.getItem("@cookies-concash")
  );

 const estado: any = useSelector((state: any) => state)
  const navegar = useCallback(() => {
    if (window.location.search === "?redirect=santander")
      window.location.href = window.location.origin + "/santander";

    if (window.location.search === "?redirect=chevrolet")
      window.location.href = window.location.origin + "/chevrolet";

    if (window.location.search === "?redirect=chevrolet")
      window.location.href = window.location.origin + "/chevrolet";

    setTimeout(() => {
      if (window.location.hash === "#faq")
        window.location.href = `${window.location.href}`;

      if (window.location.hash === "#parceiros")
        window.location.href = `${window.location.href}`;
      if (window.location.hash.includes("/venderconsorcio")) {
        window.location.href = `${window.location.href}`;
      }
      if (window.location.hash.includes("#banrisul")) {
        window.location.href = `/#venderconsorcio`;
        setBanrisul(true);
      }
    }, 500);
  }, []);

  useEffect(() => {
    var hash = window.location.search;
    if (hash.includes("nomeRazao")) {
      var result = hash.split("&").map(function (res: any, item) {
        var parts = res.split("=");
        const chave = parts[0].replace("?", "");

        const valor = parts[1].replaceAll("%20", " ");

        return { chave: chave, valor };
      }, {});
      result.map((el: any) => {
        if (el.chave === "email") setQEmail(el.valor);
        if (el.chave === "telefone") setQPhone(el.valor);
        if (el.chave === "nomeRazao") setQName(el.valor);
        if (el.chave === "cpfCnpj") setQDoc(el.valor);
      });
      setParameters(result);
    }

    if (window.location.href.includes("banrisul")) {
      localStorage.setItem("administradoraO", "114");
      window.location.href = "/venderconsorcio";
    }
    navegar();
  }, []);

  return (
    <>
      {!cookies && <Cookies setCookies={setCookies} />}
      <Helmet prioritizeSeoTags>
        <title>Concash - Vender Consórcio</title>
        <link rel="canonical" href="https://concash.com.br/" />

        <meta
          name="description"
          content="Venda seu consórcio na Concash com a melhor negociação e receba seu dinheiro à vista, no prazo mais rápido do mercado. Solicite sua proposta."
        />
        <meta property="og:title" content="Concash - Vender Consórcio" />

      </Helmet>
      <V4Header />
      <V4Content>
        <V4HeroSection />
        <V4AdminstradoraSection />
        <V4TopicsSection />
        <V4NumbersSection />
        <V4ApprovesSection />
        <V4BlogPosts />
        <V4ContactSection />
        <V4Footer />
      </V4Content>
    </>
  );
}

export default V4Page;
