import { Switch } from "@mui/material";
import styled from "styled-components";

import { styled as smui } from "@mui/material/styles";
import { V4TextSmall } from "../../commons/Tipography";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeCookie } from "../../../../store/optout/optout.slide";

export const Container = styled.div`
  display: flex;
  padding: 24px;
  position: fixed;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  @media (min-width: 1025px) {
    flex-direction: column;
    width: 537px;
    bottom: 10px;
    right: 60px;
  }
  @media (max-width: 1024px) {
    bottom: 0;
    width: 100%;
    right: 0;
    flex-direction: column;
  }
`;

export const ContainerCookies = styled.div`
  display: flex;
  padding: 24px;
  position: fixed;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  justify-content: space-between;
  @media (min-width: 1025px) {
    flex-direction: column;
    width: 764px;
    height: 553px;
    bottom: 10px;
    right: 60px;
  }
  @media (max-width: 1024px) {
    bottom: 0;
    width: 100%;
    right: 0;
    max-height: 100%;
    flex-direction: column;
  }
`;

export const V4Switch = smui(Switch)(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 18,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(20px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    borderRadius: 9,
    backgroundColor: "#fff",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
  },
}));

interface V4CookieItemProps {
  text: string;
  onChange?: (checked: boolean) => void;
  title: string;
  type: string;
}

export const V4CookieItem = ({
  onChange,
  text,
  title,
  type,
}: V4CookieItemProps) => {

  const {optout}: any = useSelector(state => state);
  const dispatch = useDispatch();
  function handleChange() {
    return dispatch(changeCookie({cookie: type, valor: !optout[type]}));
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <V4TextSmall style={{ fontWeight: "600" }}>{title}</V4TextSmall>
        </div>
        <div>
          <V4Switch checked={optout[type]} onChange={handleChange} />
        </div>
      </div>
      <div>
        <V4TextSmall>{text}</V4TextSmall>
      </div>
    </div>
  );
};
