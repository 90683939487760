import React from 'react';
import styled from 'styled-components';
import { SlMenu } from "react-icons/sl";
import { V4TextButton, V4TextRegular } from '../Tipography';

const V4StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
`;

interface V4HamburgButtonProps {
  onclick: any;
}

interface V4ButtonSubmitProps {
  onclick?: () => void;
  color?: string;
  buttoncolor?: string;
  radius?: any;
  border?: any;
  text: string;
  icon?: any;
  fontSize?: any;
  width?: any;
  height?: any;
  disabled?: boolean;
  position?: 'LEFT' | 'RIGHT';
  type: 'LINK' | 'BUTTON';
  link?: string;
  gap?: any;
}

export const V4HamburgButton: React.FC<V4HamburgButtonProps> = ({ onclick }: V4HamburgButtonProps) => {
  return (
    <>
      <V4StyledButton onClick={onclick}>
        <SlMenu width={24} />
      </V4StyledButton>
    </>
  )
};

export const V4ButtonSubmit: React.FC<V4ButtonSubmitProps> = ({ icon, text, color,buttoncolor,  onclick, border, radius, position, fontSize, width, height, disabled, type, link }) => {
  return (
    <>
    {type === 'LINK' ? (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a 
        href={!disabled ? link : ""}
      style={{
        display: 'flex',
        gap: 10,
        textDecoration: 'none',
        cursor: disabled ? 'not-allowed' : 'pointer',
        alignItems: 'center',
        width: width ? width: 'auto',
        height: height ? height : '38px',
        justifyContent: position === 'RIGHT' ? 'row-reverse' : 'center',
        backgroundColor: buttoncolor ? buttoncolor : '#A0ED38',
        color: color ? color : "#000",
        fontSize: fontSize ? fontSize : 14,
        borderRadius: radius ? radius : 16,
        border: border ? border : 'none',
      }}
        
      >
        {icon}<V4TextButton style={{ color: color ? color : "#FFF"}}>{text}</V4TextButton>
      </a>
    ) : (
      <button style={{
        display: 'flex',
        gap: 10,
        cursor: disabled ? 'not-allowed' : 'pointer',
        alignItems: 'center',
        width: width ? width: 'auto',
        height: height ? height : '38px',
        justifyContent: position === 'RIGHT' ? 'row-reverse' : 'center',
        backgroundColor: buttoncolor ? buttoncolor : '#A0ED38',
        color: color ? color : "#000",
        fontSize: fontSize ? fontSize : 14,
        borderRadius: radius ? radius : 16,
        border: border ? border : 'none'
      }}
        onClick={onclick}
        disabled={disabled}
      >
        {icon}{text}
      </button>
    )}
    </>
  )
};

export const V4ButtonSimple: React.FC<V4ButtonSubmitProps> = ({ icon, text, color,buttoncolor,  onclick, border, radius, position, fontSize, width, height, disabled, type, link, gap }) => {
  return (
    <>
    {type === 'LINK' ? (
      <a 
        href={!disabled ? link : ""}
      style={{
        display: 'flex',
        gap: gap ? gap : 10,
        textDecoration: 'none',
        cursor: disabled ? 'not-allowed' : 'pointer',
        alignItems: 'center',
        width: width ? width: 'auto',
        height: height ? height : '72px',
        justifyContent: position === 'RIGHT' ? 'row-reverse' : 'flex-start',
        backgroundColor: buttoncolor ? buttoncolor : 'transparent',
        color: color ? color : "#000",
        fontSize: fontSize ? fontSize : 14,
        border: 'none',
        borderBottom: border ? border : "1px solid rgba(0,0,0,0.16)",
        borderRadius: radius ? radius : 0,
      }}
        
      >
        {icon}<V4TextButton style={{ color: color ? color : "#000"}}>{text}</V4TextButton>
      </a>
    ) : (
      <button style={{
        display: 'flex',
        gap: gap ? gap : 10,
        cursor: disabled ? 'not-allowed' : 'pointer',
        alignItems: 'center',
        width: width ? width: 'auto',
        height: height ? height : '72px',
        justifyContent: position === 'RIGHT' ? 'row-reverse' : 'flex-start',
        backgroundColor: buttoncolor ? buttoncolor : 'transparent',
        color: color ? color : "#000",
        fontSize: fontSize ? fontSize : 14,
        border: 'none',
        borderRadius: radius ? radius : 0,
        borderBottom: border ? border : "1px solid rgba(0,0,0,0.16)",
      }}
        onClick={onclick}
        disabled={disabled}
      >
        {icon}{text}
      </button>
    )}
    </>
  )
}
export const V4ButtonSimpleWeb: React.FC<V4ButtonSubmitProps> = ({ icon, text, color,buttoncolor,  onclick, border, radius, position, fontSize, width, height, disabled, type, link, gap }) => {
  return (
    <>
    {type === 'LINK' ? (
      <a 
        href={!disabled ? link : ""}
        style={{
          display: 'flex',
          padding: '24px',
          justifyContent: 'space-between',
          flexDirection: 'column',
          gap: gap ? gap : 10,
          textDecoration: 'none',
          cursor: disabled ? 'not-allowed' : 'pointer',
          alignItems: 'center',
          width: width ? width: '160px',
          maxWidth: width ? width: '160px',
          height: height ? height : '160px',
          backgroundColor: buttoncolor ? buttoncolor : 'transparent',
          color: color ? color : "#000",
          fontSize: fontSize ? fontSize : 14,
          border: border ? border : "1px solid rgba(0,0,0,0.16)",
          borderRadius: radius ? radius : 16,
        }}
        
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}}>{icon}</div>
        <span style={{ color: color ? color : "#000", textWrap: 'wrap'}}>{text}</span>
        
      </a>
    ) : (
      <button style={{
        display: 'flex',
        padding: '24px',
        flexDirection: 'column',
        justifyContent: 'space-between',

        gap: gap ? gap : 10,
        cursor: disabled ? 'not-allowed' : 'pointer',
        alignItems: 'center',
        width: width ? width: '160px',
        maxWidth: width ? width: '160px',
        height: height ? height : '160px',
        backgroundColor: buttoncolor ? buttoncolor : '#FFF',
        color: color ? color : "#000",
        fontSize: fontSize ? fontSize : 14,
        borderRadius: radius ? radius : 16,
        border: border ? border : "1px solid rgba(0,0,0,0.16)",
      }}
        onClick={onclick}
        disabled={disabled}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end'}}>{icon}</div>
        <span style={{ color: color ? color : "#000", alignSelf: 'flex-start', width: '100%', fontSize: 24, textAlign: 'left' }}>{text}</span>
      </button>
    )}
    </>
  )
}