import styled from "styled-components";

export const ContainerApproves = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #F5F5F6;
    @media (max-width: 1024px) {
        padding: 60px 24px;
        gap: 24px;
    }
    @media (min-width: 1025px) {
        padding: 80px 60px;
    }
`;