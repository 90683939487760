import styled from "styled-components";

export const V4Content = styled.aside`
    display: flex;
    background-color: #FFF;
    margin-top: 73px;
    flex-direction: column;
`;
export const V4Onboarding = styled.aside`
    display: flex;
    background-color: #f5f5f6;
    margin-top: 73px;
    flex-direction: column;
    height: 100vh;
`;