import { Backdrop } from "@mui/material";
import React from "react";
import { GrClose } from "react-icons/gr";
import styled from "styled-components";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import "../../../assets/V4/css/orquestra.css";
import { V4ButtonSubmit } from "../commons/Button";
import { V4TextMediumBold, V4TextRegular, V4TextSmall } from "../commons/Tipography";

export const V4Modal = (props: any) => {
  const { open, type, actionClose, title, text, html, height, width, btnColor, textClose, estilo, colorIconOK } = props;
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 999 }}>
        <ModalBoxStyled style={{ height: height, width: width, fontFamily: "" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <GrClose
              onClick={() => actionClose(false)}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              marginBottom: 10,
            }}
          >
            {type === "success" && (
              <FaCheckCircle color={colorIconOK ? colorIconOK : 'green'} size={50} />
            )}
            {type === "error" && (
              <MdCancel color={'red'} size={50} />
            )}

            <V4TextRegular style={{ textAlign: "center"}}>{title}</V4TextRegular>
            <V4TextSmall style={{ textAlign: "center", marginTop: 10 }}>
              {text}
            </V4TextSmall>
            {html}
          </div>

          <V4ButtonSubmit 
          type="BUTTON"
            text={textClose ? textClose : "Fechar"}
            width={"100%"}
            onclick={() => actionClose(false)}
          /> 
        </ModalBoxStyled>
      </Backdrop>
    </>
  );
};

const ModalBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
  min-height: 230px;
  font-family: "M";
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 20px;
  justify-content: space-between;
  margin: 10px;

`;
