export const listAdm = (adm) => {
    const payloadAdm = {
        'santander': { id: 62, 'title': 'Santander', 'text': 'Uma parceria de sucesso', name: 'SANTANDER BRASIL ADMINISTRADORA DE CONSORCIO LTDA.' },
        'porto': { id: 46, 'title': 'Porto', 'text': 'Uma parceria de sucesso', name: 'PORTO SEGURO ADMINISTRADORA DE CONSORCIOS LTDA' },
        'chevrolet': { id: 22, 'title': 'Chevrolet', 'text': 'Uma parceria de sucesso', name: "GMAC ADMINISTRADORA DE CONSORCIOS LTDA (CHEVROLET)"  },
        'consorciogm': { id: 22, 'title': 'Chevrolet', 'text': 'Uma parceria de sucesso', name: "GMAC ADMINISTRADORA DE CONSORCIOS LTDA (CHEVROLET)" },
        'volkswagen': { id: 138, 'title': 'Volkswagen', 'text': 'Uma parceria de sucesso', name: 'CONSORCIO NACIONAL VOLKSWAGEN - ADMINISTRADORA DE CONSORCIO LTDA.' },
        'daf': { id: 51, 'title': 'DAF', 'text': 'Uma parceria de sucesso', name: 'RANDON ADMINISTRADORA DE CONSORCIOS LTDA.' },
        'randon': { id: 51, 'title': 'Randon', 'text': 'Uma parceria de sucesso', name: 'RANDON ADMINISTRADORA DE CONSORCIOS LTDA.' },
        'canopus': { id: 125, 'title': 'Canopus', 'text': 'Uma parceria de sucesso', name: 'CANOPUS ADMINISTRADORA DE CONSORCIOS S/A' },
        'bradesco': { id: 118, 'title': 'Bradesco', 'text': 'Uma parceria de sucesso', name: 'BRADESCO ADMINISTRADORA DE CONSORCIOS LTDA.' },
        'compracota': { id: 118, 'title': 'Bradesco', 'text': 'Uma parceria de sucesso', name: 'BRADESCO ADMINISTRADORA DE CONSORCIOS LTDA.' },
    };

    return payloadAdm[adm];
}


