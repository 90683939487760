function realNaN(chart) {
  const value = parseInt(chart, 10);
  return isNaN(value);
}

function createFormater(mask) {
  const computedMask = mask.split("");

  return (value) => {
    if (value.length === 0) {
      return "";
    }

    let valueIndex = 0;
    return computedMask
      .map((chart, index) => {
        const v = value[valueIndex];

        if (!v && realNaN(chart) && index === 0) {
          return chart;
        }

        if (!v) {
          return "";
        }

        if (realNaN(chart)) {
          return chart;
        }

        valueIndex += 1;
        return v;
      })
      .join("");
  };
}

const cellphone = createFormater("(11) 91234-5678");
const phone = createFormater("(11) 1234-5678");

export const cellphoneFormater = (text) => {
  text = text.replace(/([^0-9])/g, "").slice(0, 11);

  if (text.length < 11) {
    return phone(text);
  }
  return cellphone(text);
};

export const currencyFormater = (text) => {
  const numero = text.replace(/([^0-9])/g, "");
  return new Intl.NumberFormat("pt-BR", {
    style: "decimal",
  }).format(numero);
};

export const numberFormater = (text) => {
  return text.replace(/([^0-9])/g, "");
};

export const formataCPF = (text) => {
  const texto = text.replace(/[^\d]/g, "");
  const texx = texto.toString().padStart(11, "0");
  const textoF = texx
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    .slice(0, 14);
  return textoF;
};

export const formataCNPJ = (text) => {
  const texto = text.replace(/[^\d]/g, "");
  const texx = texto.toString().padStart(14, "0");
  const textoF = texx
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return textoF;
};

export   function fillCPF(cpf) {
  const onlyDigits = cpf?.replace(/[^\d]/g, "");
  return onlyDigits?.padStart(11, "0");
}