import React, { useState } from 'react';
import { V4AdminstradoraSection, V4Footer, V4Header } from '../../../components/V4';
import { V4Onboarding } from '../../../components/V4/commons.styled';
import { HeadContent, ImageOnboarding } from './onboarding.styles';
import HeadOnboarding from "../../../assets/V4/onboarding/head-hero.svg";
import Woman from "../../../assets/V4/onboarding/woman.svg";
import { V4TextMediumBold, V4TextRegular, V4TextSmall, V4Title } from '../../../components/V4/commons/Tipography';
import { V4OnboardingAdminstradoraSection } from '../../../components/V4/AdminstradoraSection';
import { V4ButtonSubmit } from '../../../components/V4/commons/Button';
import { V4FormBoxStyled, V4HeroSectionStyled, V4ImageBoxStyled } from '../../../components/V4/HeroSection/hero.styled';
import { V4Input } from '../../../components/V4/commons/Input';
import HeroImage from "../../../assets/V4/hero-image.jpg";
import { v3ValidateEmail } from '../../../styles/themes/utils/validate';
import { v3CellphoneFormater } from '../../../styles/themes/utils/format';
import api from '../../../services/api';
import { listAdm } from '../../../components/V4/HeroSectionLanding/list.adm';
import { useDispatch, useSelector } from 'react-redux';
import { setLeadId, startRegister } from '../../../store/register/register.slice';
import { V4Modal } from '../../../components/V4/Modal';
import { V4HeadOnboarding } from '../../../components/V4/commons/HeadOnboarding';
import { acceptRequiredCookies } from '../../../store/optout/optout.slide';
import { Helmet } from 'react-helmet-async';


const V4OnboardingStart: React.FC = () => {
    const baseLocal = useSelector((state: any) => state.register);
    const dispatch = useDispatch();
    const [width, setWidth] = React.useState(window.innerWidth);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneErr, setPhoneErr] = useState(false);
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState(false);

    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");


    function handleChangeEmail(t: string) {
        const text = t?.replaceAll(" ", "");
        if (v3ValidateEmail(text) || text === "") {
            setEmail(text);
            setEmailErr(false);
        } else {
            setEmail(text);
            setEmailErr(true);
        }
    }

    function handleChangeName(n: string) {
        setName(n)
    }

    function handleChangePhone(t: string) {
        const validatePhone = t.replace(/[^0-9]/g, "").split("");
        if (t) {
            setPhoneErr(false);
            if (t.length > 15) {
                setPhone(phone);
            } else {
                setPhone(v3CellphoneFormater(t));
            }
        }
        if (!t || t == "" || t == "(") {
            setPhoneErr(true);
            setPhone(t);
        }

        if (t.length < 14 || validatePhone[0] === "0") {
            setPhoneErr(true);
        } else {
            setPhoneErr(false);
        }
    }


    async function handleSendForm() {
        if(!name) {
          setErrorText("Favor informar o nome.");
          return setHasError(true);
        }
        if(!phone || phoneErr) {
          setErrorText("Favor informar o telefone corretamente.");
          return setHasError(true);
        }
        if(!email || emailErr) {
          setErrorText("Favor informar o telefone corretamente.");
          return setHasError(true);
        }
        let formData = new FormData();
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("name", name);
        formData.append("origin", "Landing Page");
        if (phone && !phoneErr && !emailErr && name) {
                    dispatch(acceptRequiredCookies());
        
            dispatch(startRegister({name, phone, email}))
            const {data} = await api.post("/api/campanha-lead/1.0/lead", formData).then(() => {
              dispatch(setLeadId({leadId: data?.id}));
              return window.location.href = "/onboarding/step2";
            });
            return;
        }
    }

    React.useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <>
              <Helmet title="Onboarding | Concash" />
              <Helmet>
                <meta
                  name="descripton"
                  content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
                />
              </Helmet>
                <V4Modal
                  open={hasError}
                  actionClose={()=> setHasError(false)}
                  title={"Atenção!"}
                  text={errorText}
                  type="error"
                />
                <V4Modal
                  open={hasSuccess}
                  actionClose={setHasSuccess}
                  title={"Sucesso!"}
                  text={successText}
                  type="success"
                />
            <V4Header />
            <V4Onboarding>
        <V4HeadOnboarding breadcrumb="Passo 1: Quem é o titular?" />

                <>
                    <V4HeroSectionStyled >
                        <V4FormBoxStyled>
                            <V4Title>Informa seu nome e, ao menos, um contato</V4Title>

                            <V4Input placeholder={"Digite seu nome"} onchange={(e: any) => handleChangeName(e.target.value)} value={name} />
                            {width > 1024 && (
                                <div style={{ display: 'flex', gap: 10, width: '100%', marginBottom: 24 }}>
                                    <V4Input placeholder={"Telefone"} width='100%'
                                        onchange={(e: any) => handleChangePhone(e.target.value)}
                                        value={phone}
                                        error={phoneErr}
                                    />
                                    <V4Input placeholder={"E-mail"} width='100%'
                                        onchange={(e: any) => handleChangeEmail(e.target.value)}
                                        value={email} error={emailErr} />
                                </div>)}
                            {width <= 1024 && (
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 26, marginTop: 16, marginBottom: 24 }}>
                                    <V4Input placeholder={"Telefone"} width='100%'
                                        onchange={(e: any) => handleChangePhone(e.target.value)}
                                        value={phone}
                                        error={phoneErr}

                                    />
                                    <V4Input placeholder={"E-mail"} width='100%'
                                        onchange={(e: any) => handleChangeEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>)}
                            {width <= 1024 ? (
                                <V4ButtonSubmit
                                    type='BUTTON'
                                    text='Enviar dados'
                                    radius={'30px'}
                                    onclick={handleSendForm}
                                />
                            ) : (
                                <V4ButtonSubmit
                                    type='BUTTON'
                                    text='Enviar dados'
                                    radius={'16px'}
                                    height={56}
                                    width={257}
                                    onclick={handleSendForm}
                                />
                            )}
                            <V4TextSmall style={{ color: "rgba(0,0,0,0.64"}}>Ao continuar, você concorda em compartilhar seus dados com a Concash, de acordo com as leis de proteção de dados LGPD.</V4TextSmall>
                        </V4FormBoxStyled>
                    </V4HeroSectionStyled>
                </>
            </V4Onboarding>
            <V4Footer />
        </>
    )
};

export default V4OnboardingStart;