import React, { useState, useEffect, useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import {
  MdOutlineMail,
  MdOutlineLocationOn,
  MdLocationPin,
} from "react-icons/md";
import { RiPhoneLine, RiWhatsappFill } from "react-icons/ri";
import { LabelForm } from "../../components/Form";
import {
  InputEmail,
  InputPhone,
  InputText,
  InputTextArea,
} from "../../components/Inputs";
import { SSearcheable, V3Searcheable } from "../../components/Selects";
import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import {
  H1,
  H3,
  H5,
  LinkGray,
  LinkWhite,
  TextBigGray,
  TextSmall,
} from "../../components/shared/Typography";
import api from "../../services/api";
import { colors } from "../../styles/colors";
import { ContactContainer, ContactContainerMobile } from "./styles";
import { ButtonLarge } from "../../components/Buttons/button";
import Swal from "sweetalert2";
import ScrollButton from "../../components/ScrollButton";

import Helmet from "react-helmet";
import {
  V3Select,
  V3TextCustom,
  V3TextCustomContact,
} from "../../styles/themes/components/input";
import { v3CellphoneFormater } from "../../styles/themes/utils/format";
import { V3Modal } from "../../styles/themes/components/modal";
import { v3ValidateEmail } from "../../styles/themes/utils/validate";
import { V3Button } from "../../styles/themes/components/button";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import { Backdrop, CircularProgress } from "@mui/material";
import { HiPhone } from "react-icons/hi";
import { FiMail } from "react-icons/fi";
import { V4Footer, V4Header } from "../../components/V4";

function Contact(): JSX.Element {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [onLoad, setOnLoad] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [telefoneErr, setTelefoneErr] = useState(false);
  const [admSelect, setAdmSelect] = useState([]);
  const [adm, setAdm] = useState("");
  const [tipoAtend, setTipoAtend] = useState([]);
  const [atend, setAtend] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [mensagem, setMensagem] = useState("");

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  function handleClear() {
    setName("");
    setTelefone("");
    setAtend("");
    setAdm("");
    setEmail("");
    setMensagem("");
    setEmpresa("");
  }

  window.addEventListener("resize", ajustarTela);
  function ajustarTela() {
    const tamanho = window.innerWidth;
    if (tamanho <= 1000) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  function handleChangeSelectAdm(e: any) {
    if (e) {
      setAdm(e.id);
    } else {
      setAdm("");
    }
  }

  function handleSucesso(state: any){
    setHasSuccess(state);
    window.location.reload();
  }

  function handleChangeSelectTipo(e: any) {
    if (e) {
      setAtend(e.id);
    } else {
      setAtend("");
    }
  }

  async function getAdm() {
    setAdmSelect([]);
    try {
      const { data } = await api.get("/api/administradoras/1.0/listar");
      let arrAdm: any = [];

      data.forEach((d: any) => {
        arrAdm.push({ label: d.nome, id: d.id });
      });
      setAdmSelect(arrAdm);
    } catch (error) {
      console.log(error);
    }
  }
  async function getTipoAtend() {
    setTipoAtend([]);
    try {
      const { data } = await api.get("/api/fale-conosco-tipo/1.0/listar");
      let arrAt: any = [];

      data.forEach((d: any) => {
        arrAt.push({ label: d.descricao, id: d.id });
      });
      setTipoAtend(arrAt);
    } catch (error) {
      console.log(error);
    }
  }

  function erro(text: string) {
    Swal.fire({
      title: "Erro!",
      text: text,
      icon: "error",
      confirmButtonText: "OK",
      confirmButtonColor: "#28A588",
    });
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    const formData = new FormData();
    const cpf_cnpj = "";

    if (!name || nameErr) {
      setNameErr(true);
      setErrorText("Preencha corretamente o campo Nome / Razão Social.");
      return setHasError(true);
    }
    if (!telefone || telefoneErr) {
      setTelefoneErr(true);
      setErrorText("Preencha corretamente o campo telefone.");
      return setHasError(true);
    }
    if (!v3ValidateEmail(email) || !email) {
      setEmailErr(true);
      setErrorText("Preencha corretamente o campo e-mail.");
      return setHasError(true);
    }

    if (!atend) {
      setErrorText("Por favor selecione o tipo de atendimento que deseja.");
      return setHasError(true);
    }

    if (!mensagem) {
      setErrorText("Por favor digite sua mensagem.");
      return setHasError(true);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const telefoneCompleto = telefone.split(" ");
    const ddd = parseInt(telefoneCompleto[0].replace(/[^0-9]/g, ""));
    const telefoneP = parseInt(telefoneCompleto[1].replace(/[^0-9]/g, ""));
    setOnLoad(true);
    const response = await api
      .post("/api/site/1.0/fale-conosco", {
        nomeRazao: name,
        telefone: telefoneP,
        email: email,
        dddTelefone: ddd,
        empresa: empresa,
        idAdministradora: adm,
        tipoFaleConoscoId: atend,
        mensagem: mensagem,
      })
      .then((response: any) => {
        setOnLoad(false);
        setSuccessText(
          "Agora é só aguardar, um de nossos consultores entrará em contato com você."
        );
        return setHasSuccess(true)

      })
      .catch((err: any) => {
        setErrorText("Houve um erro no envio da sua mensagem.");
        setHasError(true);
      });
  }

  function handleTelefone(t: any) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneErr(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneErr(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneErr(true);
    } else {
      setTelefoneErr(false);
    }
  }

  function handleEmail(t: any) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  useEffect(() => {
    getAdm();
    getTipoAtend();
  }, []);

  return (
    <>
      <Backdrop open={onLoad} style={{ zIndex: 999 }}>
        <div>
          <Lottie animationData={Loading} />
        </div>
      </Backdrop>
      <V3Modal
        open={hasError}
        actionClose={setHasError}
        title={"Atenção."}
        text={errorText}
        height={250}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={handleSucesso}
        title={"Agradecemos o seu contato."}
        height={250}
        text={successText}
        type="success"
      />
      <Helmet title="Entre em contato conosco | Concash" />
      <Helmet>
        <meta
          name="description"
          content="Acreditamos que atender bem o nosso cliente é estar sempre por perto quando ele precisar. Se seus planos mudaram a gente pode te ajudar."
        />
      </Helmet>

      <V4Header />
      <div style={{ backgroundColor: "#fff", overflow: "hidden" }}>
        {!isMobile ? (
          <>
            <ContactContainer>
              <div className="title-box">
                <span className="title">Fale conosco</span>
                <span className="subtitle">
                  Acreditamos que atender bem o nosso cliente é estar sempre por
                  perto quando ele precisar. <br />
                  Envie sua mensagem ou entre em contato por nossos canais de
                  atendimento!
                </span>
              </div>
              <div className="box-main">
                <div className="box-contact">
                  <V3TextCustomContact
                    required
                    size="small"
                    type="text"
                    name="Nome / Razão Social"
                    id="name"
                    label="Nome / Razão Social"
                    style={{ minHeight: 50 }}
                    error={nameErr}
                    helperText={
                      nameErr && "Preencha com seu Nome / Razão Social"
                    }
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setName(e.target.value);
                      e.target.value && setNameErr(false);
                    }}
                    value={name}
                  />
                  <V3TextCustomContact
                    size="small"
                    sx={{ width: "100%", height: 50 }}
                    onChange={(e) => {
                      handleTelefone(e.target.value);
                    }}
                    error={telefoneErr}
                    value={telefone}
                    label="Telefone"
                    helperText={
                      telefoneErr &&
                      "Telefone deve ser no formato (11) 1234-5678 ou (11) 98765-4321."
                    }
                    required
                  />
                  <V3TextCustomContact
                    sx={{ width: "100%", height: 50 }}
                    label="E-mail"
                    onChange={(e) => {
                      handleEmail(e.target.value);
                    }}
                    error={emailErr}
                    size="small"
                    helperText={emailErr && "E-mail inválido."}
                    value={email}
                    required
                  />
                  <V3Select
                    id="administradoras"
                    name="administradoras"
                    action={handleChangeSelectAdm}
                    options={admSelect}
                    height={50}
                    value={adm}
                    label="Administradora do consórcio"
                  />

                  <V3Select
                    id="tipoAtendimento"
                    name="tiposAtendimento"
                    action={handleChangeSelectTipo}
                    options={tipoAtend}
                    height={50}
                    label="Tipo de atendimento"
                    required
                  />
                  <InputTextArea
                    required
                    placeholder="Mensagem"
                    name="mensagem"
                    id="mensagem"
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                  />
                  <div className="button-box">
                    <span className="text-obr">
                      * Preenchimento obrigatório
                    </span>
                    <V3Button
                      estilo="primary"
                      text={
                        onLoad ? (
                          <CircularProgress style={{ color: "#FFF" }} />
                        ) : (
                          "Enviar mensagem"
                        )
                      }
                      width={310}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
                <div className="box-infos">
                  <div className="col-footer2">
                    <div>
                      <div className="contact-item" style={{ gap: 8 }}>
                        <FiMail size={16} />
                        <a
                          href="mailto:voce@concash.com.br"
                          style={{ textDecoration: "none", color: "#000" }}
                          className="text-contact"
                        >
                          voce@concash.com.br
                        </a>
                      </div>
                      <div className="contact-item" style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <HiPhone size={16} />
                          <a
                            href="tel:+551140405686"
                            className="text-contact"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            (11) 4040-5686
                          </a>
                        </div>
                      </div>

                      <div className="contact-item" style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <RiWhatsappFill size={16} />
                          <a
                            href="https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0"
                            className="text-contact"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            (11) 4040-5686
                          </a>
                        </div>
                      </div>
                      <div style={{ paddingLeft: 25 }}>
                        <span className="subtext">WhatsApp</span>
                      </div>
                      <div className="contact-item" style={{ marginTop: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <span className="text-contact">
                            <b style={{ fontWeight: "bold" }}>
                              Horário de Atendimento
                            </b>
                            <br /> Segunda a sexta, das 9h às 18h
                          </span>
                        </div>
                      </div>
                      <div
                        className="contact-item"
                        style={{
                          width: 270,
                          marginTop: 30,
                          marginBottom: 30,
                          borderTop: "1px solid #DDD",
                          paddingTop: 30,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 8,
                          }}
                        >
                          <MdLocationPin size={16} />
                          <span
                            className="text-contact"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Av. Pres. Juscelino Kubitschek, 1830
                            <br />
                            Itaim Bibi
                            <br />
                            CEP 04543-900
                            <br /> São Paulo – SP
                          </span>
                        </div>
                      </div>
                      <div>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.375645214737!2d-46.68924822479407!3d-23.590857278779602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce578c0aad759d%3A0xfe05c278d78a7eec!2sAv.%20Pres.%20Juscelino%20Kubitschek%2C%201830%20-%20Itaim%20Bibi%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2013571-410!5e0!3m2!1spt-BR!2sbr!4v1688137760338!5m2!1spt-BR!2sbr"
                          style={{ display: "flex", flex: 1 }}
                          height="300"
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ContactContainer>
          </>
        ) : (
          <>
            <ContactContainerMobile>
              <div className="title-box">
                <span className="title">Fale conosco</span>
                <span className="subtitle">
                  Acreditamos que atender bem o nosso cliente é estar sempre por
                  perto quando ele precisar. <br />
                  Envie sua mensagem ou entre em contato por nossos canais de
                  atendimento!
                </span>
              </div>
              <div className="box-main">
                <div className="box-contact">
                  <V3TextCustomContact
                    required
                    size="small"
                    type="text"
                    name="Nome / Razão Social"
                    id="name"
                    label="Nome / Razão Social"
                    style={{ minHeight: 50 }}
                    error={nameErr}
                    helperText={
                      nameErr && "Preencha com seu Nome / Razão Social"
                    }
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setName(e.target.value);
                      e.target.value && setNameErr(false);
                    }}
                    value={name}
                  />
                  <V3TextCustomContact
                    size="small"
                    sx={{ width: "100%", height: 50 }}
                    onChange={(e) => {
                      handleTelefone(e.target.value);
                    }}
                    error={telefoneErr}
                    value={telefone}
                    label="Telefone"
                    helperText={
                      telefoneErr &&
                      "Telefone deve ser no formato (11) 1234-5678 ou (11) 98765-4321."
                    }
                    required
                  />
                  <V3TextCustomContact
                    sx={{ width: "100%", height: 50 }}
                    label="E-mail"
                    onChange={(e) => {
                      handleEmail(e.target.value);
                    }}
                    error={emailErr}
                    size="small"
                    helperText={emailErr && "E-mail inválido."}
                    value={email}
                    required
                  />
                  <V3Select
                    id="administradoras"
                    name="administradoras"
                    action={handleChangeSelectAdm}
                    options={admSelect}
                    height={50}
                    value={adm}
                    label="Administradora do consórcio"
                  />

                  <V3Select
                    id="tipoAtendimento"
                    name="tiposAtendimento"
                    action={handleChangeSelectTipo}
                    options={tipoAtend}
                    height={50}
                    label="Tipo de atendimento"
                    required
                  />
                  <InputTextArea
                    required
                    placeholder="Mensagem"
                    name="mensagem"
                    id="mensagem"
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                  />
                  <div className="button-box">
                    <span className="text-obr">
                      * Preenchimento obrigatório
                    </span>
                    <V3Button
                      estilo="primary"
                      text={
                        onLoad ? (
                          <CircularProgress style={{ color: "#FFF" }} />
                        ) : (
                          "Enviar mensagem"
                        )
                      }
                      width={"100%"}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
                <div className="box-infos">
                  <div className="col-footer2">
                    <div>
                      <div
                        className="contact-item"
                        style={{
                          width: 250,
                          marginTop: 30,
                          marginBottom: 30,
                          paddingTop: 30,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 8,
                          }}
                        >
                          <MdLocationPin size={16} />
                          <span
                            className="text-contact"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Av. Pres. Juscelino Kubitschek, 1830
                            <br />
                            Itaim Bibi
                            <br />
                            CEP 04543-900
                            <br /> São Paulo – SP
                          </span>
                        </div>
                      </div>
                      <div style={{ marginBottom: 180 }}>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.375645214737!2d-46.68924822479407!3d-23.590857278779602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce578c0aad759d%3A0xfe05c278d78a7eec!2sAv.%20Pres.%20Juscelino%20Kubitschek%2C%201830%20-%20Itaim%20Bibi%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2013571-410!5e0!3m2!1spt-BR!2sbr!4v1688137760338!5m2!1spt-BR!2sbr"
                          style={{ display: "flex", flex: 1 }}
                          height="300"
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ContactContainerMobile>
          </>
        )}

        <V4Footer />
      </div>
    </>
  );
}

export default Contact;
