import styled from "styled-components"

export const AdminisradoraSectionStyled = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #F5F5F6;
    @media (min-width: 1025px) {
        padding: 40px 60px 110px 60px;

    }
    @media (max-width: 1024px) {
        padding: 24px;
    }
`;

export const AdministradoraBoxStyled = styled.div`
    display: flex;
        @media (min-width: 1025px) {
        flex-direction: row;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const AdministradoraGridStyled = styled.div`
display: flex;
width 100%;
    @media (min-width: 1025px){
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    }
    @media (max-width: 1024px){
    flex-direction: column;
    }
`;