import React from 'react';
import { BoxWeb, TextAbsolut, V4TopicsBlockContent, V4TopicsSectionMobStyled, V4TopicsSectionWebStyled, V4Transparency } from './topics.styled';
import { V4SubTitle, V4TextRegular, V4Title } from '../commons/Tipography';
import { V4ItemTopics } from './components/Items.topics';
import { V4ButtonSubmit } from '../commons/Button';

// import { Container } from './styles';

export const V4TopicsSection: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {width <= 1024 ? (
        <V4TopicsSectionMobStyled>
          <V4Transparency>
            <V4TopicsBlockContent>
              <div style={{ marginBottom: 30 }}>
                <V4SubTitle>É muito fácil vender o seu consórcio</V4SubTitle>
                <V4TextRegular>Venda sua cota e transforme em dinheiro agora mesmo.</V4TextRegular>
              </div>
              <V4ItemTopics />
              <div style={{ height: 80 }} />
              <V4ButtonSubmit
                type='LINK'
                link={"#form"}
                text='Vender consórcio'
                radius={'16px'}
                width={'100%'}
                color={"#000"}
                height={"48px"}
              />
            </V4TopicsBlockContent>
          </V4Transparency>
        </V4TopicsSectionMobStyled>
      ) : (
        <>
          <div>
            <V4TopicsSectionWebStyled />
            <BoxWeb>
              <div>
                <V4SubTitle>É muito fácil vender o seu consórcio</V4SubTitle>
                <V4TextRegular>Venda sua cota e transforme em dinheiro agora mesmo.</V4TextRegular>
              </div>
              <V4ItemTopics />
              <div style={{ height: 40 }} />
              <V4ButtonSubmit
                type='LINK'
                link={"#form"}
                text='Vender consórcio'
                radius={'16px'}
                color={"#000"}

                height={56}
                width={257}
              />
            </BoxWeb>
          </div>
        </>
      )}
    </>
  )
};