import styled from "styled-components";

export const BoxCardStyled = styled.a`
text-decoration: none;
display: flex;
box-sizing: border-box;
flex-direction: row;
color: #000;
cursor: pointer;
    @media (min-width: 1025px) {
        justify-content: flex-start;
        align-items: flex-start;
        // padding: 16px 24px;
        gap: 16px;
        isolation: isolate;
        background-color: #FFF;
        width: 312px;
        min-width: 310px;
        height: 95px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 8px;
    }
    @media (max-width: 1024px) {

        align-items: center;
        padding: 16px 0px;
        gap: 16px;

        width: 100%;
        height: 72px;
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }
`;
export const BoxCardOnboardingStyled = styled.div`
text-decoration: none;
display: flex;
box-sizing: border-box;
flex-direction: row;
color: #000;
cursor: pointer;

    @media (min-width: 1025px) {
        justify-content: flex-start;
        align-items: flex-start;
        // padding: 16px 24px;
        gap: 16px;
        isolation: isolate;
        background-color: #FFF;
        width: 312px;
        min-width: 310px;
        height: 95px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 8px;
    }
    @media (max-width: 1024px) {

        align-items: center;
        padding: 16px 0px;
        gap: 16px;

        width: 100%;
        height: 72px;
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }
`;

export const CellTextCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 16px;
`;

export const BoxImageStyled = styled.div`
@media (min-width: 1025px) {

box-sizing: border-box;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-right: 29px;
padding-left: 29px;
height: 100%;
gap: 10px;
background: #FFFFFF;
border-right: 0.5px solid rgba(0, 0, 0, 0.16);
border-radius: 7px 0px 0px 7px;

 max-width: 93px;
 max-height: 93px;
}
`;