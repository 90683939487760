import { configureStore } from "@reduxjs/toolkit";
import { registerReducer } from "./register/register.slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { optoutReducer } from "./optout/optout.slide";

const persistConfig = {
  key: "root",
  storage,
};

const persistedRegisterReducer = persistReducer(persistConfig, registerReducer);
const persistedOptoutReducer = persistReducer(persistConfig, optoutReducer);

export const store = configureStore({
  reducer: {
    register: persistedRegisterReducer,
    optout: persistedOptoutReducer
  },
});

export const persistor = persistStore(store);