import React, { useState } from "react";
import {
  AdminisradoraSectionStyled,
  AdministradoraBoxStyled,
  AdministradoraGridStyled,
} from "./administradora.styled";
import { V4Title } from "../commons/Tipography";
import {
  CardAdministradora,
  CardOnboardingAdministradora,
  CardOnboardingAdministradoraOutros,
} from "./components/AdminstradoraCard";
import { SelectAdministradora } from "../../../assets/V4/SelectAdministradora";

// import { Container } from './styles';

export const V4AdminstradoraSection: React.FC = () => {
  const [ openOutras, setOpenOutras ] = useState(false);

  return (
    <>
      <SelectAdministradora open={openOutras} onclose={() => setOpenOutras(false)} />
      <AdminisradoraSectionStyled>
        <V4Title>Selecione sua administradora</V4Title>
        <div style={{ display: "flex", height: 24 }} />
        <AdministradoraGridStyled>
          <CardAdministradora administradora="santander" />
          <CardAdministradora administradora="porto" />
          <CardAdministradora administradora="vw" />
          <CardAdministradora administradora="canopus" />
          <CardAdministradora administradora="randon" />
          <CardAdministradora administradora="daf" />
          <CardAdministradora administradora="bradesco" />
          <CardOnboardingAdministradoraOutros onclick={() => setOpenOutras(true)} />
        </AdministradoraGridStyled>
      </AdminisradoraSectionStyled>
    </>
  );
};

export const V4OnboardingAdminstradoraSection: React.FC = () => {
  const [ openOutras, setOpenOutras ] = useState(false);
  return (
    <>
      <SelectAdministradora open={openOutras} onclose={() => setOpenOutras(false)}  />
      <AdminisradoraSectionStyled>
        <V4Title>Selecione sua administradora</V4Title>
        <div style={{ display: "flex", height: 24 }} />
        <AdministradoraGridStyled>
          <CardOnboardingAdministradora administradora="santander" idAdm={62} />
          <CardOnboardingAdministradora administradora="porto" idAdm={46} />
          <CardOnboardingAdministradora administradora="vw" idAdm={138} />
          <CardOnboardingAdministradora administradora="canopus" idAdm={125} />
          <CardOnboardingAdministradora administradora="randon" idAdm={51} />
          <CardOnboardingAdministradora administradora="daf" idAdm={51} />
          <CardOnboardingAdministradora administradora="bradesco" idAdm={118} />
          <CardOnboardingAdministradoraOutros onclick={() => setOpenOutras(true)} />
        </AdministradoraGridStyled>
      </AdminisradoraSectionStyled>
    </>
  );
};
