import { Autocomplete, Backdrop } from '@mui/material';
import React from 'react';
import admList from "../../../assets/V4/admin.json";
import { BoxSelect, Container, Line, V4AdministradoraSelectItem } from './components';
import { CustomTextField } from '../../../pages/Landing/LandingPage/components/CotaForm/CotaForm.styled';
import { V4TextMediumBold, V4TextRegular } from '../../../components/V4/commons/Tipography';
import { MdClose } from 'react-icons/md';
import { V4Input } from '../../../components/V4/commons/Input';

interface SelectAdministradoraProps {
  open: boolean;
  onclose: any;
}
export const SelectAdministradora = ({open, onclose}: SelectAdministradoraProps) => {
    const [width, setWidth] = React.useState(window.innerWidth);
  const [ adms, setAdms] = React.useState([]);
  const [ admsReceived, setAdmsReceived] = React.useState([]);
  const [ textSearch, setTextSearch ] = React.useState("");

  React.useEffect(() => {
    if(admsReceived.length > 0) return;
    const dados:any = admList.map((adm: any) => ({id: adm['ID'], label: adm['Administradora']}));
    setAdms(dados);
    setAdmsReceived(dados);
  }, []);

  React.useEffect(() => {
    if(textSearch.length > 0) {
      const filtered = admsReceived.filter((adm: any) => adm.label.toLowerCase().includes(textSearch.toLowerCase()));
      setAdms(filtered);
    }
  }, [textSearch]);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Backdrop
        open={open}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container>
          <Line>
            <V4TextRegular>Selecione sua administradora</V4TextRegular>
            <button onClick={onclose} style={{ backgroundColor: "transparent", border: 'none', cursor: 'pointer'}}>
              <MdClose size={30} />
            </button>
          </Line>
          <Line style={{ marginTop: "20px"}}>
            <V4Input placeholder='Busque sua administradora' value={textSearch} width={"100%"} onchange={(e: any) => setTextSearch(e.target.value)} />
          </Line>
          <BoxSelect>

            {adms?.map((adm: any) => (
              <V4AdministradoraSelectItem key={adm.id} label={adm.label} id={adm.id} />
           ))}
          </BoxSelect>
          
        </Container>
      </Backdrop>
    </>
  );
};
