import React, { useState } from "react";
import HeroImage from "../../../assets/V4/hero-graf.png";
import {
  V4FormBoxStyled,
  V4HeroSectionStyled,
  V4ImageBoxStyled,
} from "./hero.styled";
import { V4TextRegular, V4TextSmall, V4Title } from "../commons/Tipography";
import { V4Input } from "../commons/Input";
import { V4ButtonSubmit } from "../commons/Button";
import { useDispatch, useSelector } from "react-redux";
import { v3ValidateEmail } from "../../../styles/themes/utils/validate";
import { v3CellphoneFormater } from "../../../styles/themes/utils/format";
import {
  setLeadId,
  startRegister,
} from "../../../store/register/register.slice";
import api from "../../../services/api";
import { V4Modal } from "../Modal";
import { Checkbox } from "@mui/material";
import {
  acceptAllCookies,
  acceptRequiredCookies,
} from "../../../store/optout/optout.slide";

export const V4HeroSection: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const baseLocal = useSelector((state: any) => state.register);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [accepted, setAccepted] = useState(false);

  function handleChangeEmail(t: string) {
    const text = t?.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailErr(false);
    } else {
      setEmail(text);
      setEmailErr(true);
    }
  }

  function handleChangeName(n: string) {
    setName(n);
  }

  function handleChangePhone(t: string) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setPhoneErr(false);
      if (t.length > 15) {
        setPhone(phone);
      } else {
        setPhone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setPhoneErr(true);
      setPhone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setPhoneErr(true);
    } else {
      setPhoneErr(false);
    }
  }

  async function handleSendForm() {
    if (!name) {
      setErrorText("Favor informar o nome.");
      return setHasError(true);
    }
    if (!phone || phoneErr) {
      setErrorText("Favor informar o telefone corretamente.");
      return setHasError(true);
    }
    if (!email || emailErr) {
      setErrorText("Favor informar o telefone corretamente.");
      return setHasError(true);
    }

    let formData = new FormData();
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("name", name);
    formData.append("origin", "Web Site");
    formData.append("contactAllowed", "true");
    if (phone && !phoneErr && !emailErr && name) {
      dispatch(startRegister({ name, phone, email }));
      const { data } = await api
        .post("/api/campanha-lead/1.0/lead", formData)
        .then(() => {
          dispatch(acceptRequiredCookies());
          dispatch(setLeadId({ leadId: data?.id }));
          return (window.location.href = "/onboarding/step3");
        });
      return;
    }
  }

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <V4Modal
        open={hasError}
        actionClose={() => setHasError(false)}
        title={"Atenção!"}
        text={errorText}
        type="error"
      />
      <V4Modal
        open={hasSuccess}
        actionClose={setHasSuccess}
        title={"Sucesso!"}
        text={successText}
        type="success"
      />
      <V4HeroSectionStyled id="form">
        <V4FormBoxStyled>
          <V4Title>Venda seu consórcio agora mesmo!</V4Title>
          <V4TextRegular>
            Somos uma empresa do grupo BTG Pactual, líderes na compra de
            consórcios e parceiros das principais administradoras do Brasil.
          </V4TextRegular>
          <V4Input
            placeholder={"Digite seu nome"}
            onchange={(e: any) => handleChangeName(e.target.value)}
            value={name}
          />
          {width > 1024 && (
            <div
              style={{
                display: "flex",
                gap: 10,
                width: "100%",
              }}
            >
              <V4Input
                placeholder={"Telefone"}
                width="100%"
                onchange={(e: any) => handleChangePhone(e.target.value)}
                value={phone}
                error={phoneErr}
              />
              <V4Input
                placeholder={"E-mail"}
                width="100%"
                onchange={(e: any) => handleChangeEmail(e.target.value)}
                value={email}
                error={emailErr}
              />
            </div>
          )}
          {width <= 1024 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 26,
                marginTop: 16,
              }}
            >
              <V4Input
                placeholder={"Telefone"}
                width="100%"
                onchange={(e: any) => handleChangePhone(e.target.value)}
                value={phone}
                error={phoneErr}
              />
              <V4Input
                placeholder={"E-mail"}
                width="100%"
                onchange={(e: any) => handleChangeEmail(e.target.value)}
                value={email}
                error={emailErr}
              />
            </div>
          )}
  <div style={{ height: 20 }} /> 
          {width <= 1024 ? (
            <V4ButtonSubmit
              type="BUTTON"
              onclick={handleSendForm}
              text="Vender consórcio agora"
              radius={"30px"}
            />
          ) : (
            <V4ButtonSubmit
              type="BUTTON"
              onclick={handleSendForm}
              text="Vender consórcio agora"
              radius={"16px"}
              height={56}
              width={257}
            />
          )}
          <V4TextSmall style={{ color: "rgba(0,0,0,0.64" }}>
            Ao continuar, você concorda em compartilhar seus dados com a
            Concash, de acordo com as leis de proteção de dados LGPD.
          </V4TextSmall>
        </V4FormBoxStyled>
        <V4ImageBoxStyled>
          <img src={HeroImage} alt="image" />
        </V4ImageBoxStyled>
      </V4HeroSectionStyled>
    </>
  );
};
