import axios from "axios";
import Blog1 from "../../../assets/blog/Parei-de-pagar-o-consórcio-posso-resgatar-o-dinheiro-.png";
import Blog2 from "../../../assets/blog/venda-cota-3-dicas.png";
import Blog3 from "../../../assets/blog/consorcio-santander.jpg";
import Blog4 from "../../../assets/blog/entenda-o-que-e-fundo.png";

export const handleGetBlogPosts = async () => {
  
  // async function handleGetData(){
  //   const dados = await axios.get("https://blog.concash.com.br/wp-json/wp/v2/posts?per_page=3&orderby=date").then( async ({data}) => {
  //     var arrItems = [];
  //     for (let i = 0; i < await data.length; i++) {
  //       const element = data[i];
  //       const image = await axios.get(`https://blog.concash.com.br/wp-json/wp/v2/media/${element.featured_media}`).then(async ({data}) => await data.guid.rendered)
  //       arrItems.push({
  //         text: element.title.rendered,
  //         link: element.link,
  //         image: await image
  //       })
  //     }

  //     return arrItems;
  //   })
  //   .catch((e) => {
  //     console.log(e)
  //   })

  //   return await dados;
  // }
  
  // const news = await handleGetData();

  const news = [
    { text: "Confira os 7 melhores apps de controle financeiro", link: 'https://blog.concash.com.br/parei-de-pagar-o-consorcio-posso-resgatar-o-dinheiro/', image: Blog1},
    { text: "Venda cota de consórcio cancelada de forma rápida e segura: confira 3 dicas!", link: 'https://blog.concash.com.br/venda-cota-de-consorcio-cancelada-de-forma-rapida-e-segura-confira-3-dicas/', image: Blog2},
    { text: "Saiba como vender o seu consórcio Santander cancelado ou com parcelas em atraso", link: 'https://blog.concash.com.br/saiba-como-vender-o-seu-consorcio-santander-cancelado-ou-com-parcelas-em-atraso/', image: Blog3},
    { text: "Entenda o que é o fundo comum de consórcio", link: 'https://blog.concash.com.br/entenda-o-que-e-o-fundo-comum-de-consorcio/', image: Blog4},
  ]
  return news;
}