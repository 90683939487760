import styled from "styled-components";
import BG from "../../../assets/V4/3session.png";



export const V4TopicsSectionMobStyled = styled.div`

display: flex;
flex-direction: row;

box-sizing: border-box;
    @media(max-width: 1024px) {

        padding: 0px;
        margin: 0;
        
        width: 100vw;
        height: 1378px;
        
        background: #F5F5F6;
        background: url(${BG});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: calc(50% + 300px) calc(50% - 440px);
        transform: scaleX(-1);
        z-index: 99;
    }
    @media(min-width: 1025px) {

        padding: 0px;
        margin: 0;
        margin-bottom: 50px;
        width: 100vw;
        height: 671px;
        justify-content: flex-start;
        box-sizing: border-box;
        background: #F5F5F6;
        background: url(${BG});
        
        background-size: cover;
        background-repeat: no-repeat;
        background-position: calc(50% - 0px) calc(50% - 130px);
        transform: scaleX(-1);
        z-index: 99;
    }
`;

export const V4TopicsSectionWebStyled = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 64px;

    width: 100%;
    height: 671px;

    //   background-size: cover;
    

        background: url(${BG}) center/cover no-repeat;
        background-position: calc(50% - 0px) calc(50% - 130px);
        position: relative;
        transform: scaleX(-1);


`;

export const V4Transparency = styled.div`
box-sizing: border-box;
@media (max-width: 1024px) {
    display: flex;
        z-index: 9;
    position: absolute;
    width: 100vw;
    height: 1378px;
    background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 47%, rgba(0,0,0,0) 75%);
}
`;

export const V4TopicsBlockContent = styled.div`
    box-sizing: border-box;
    display: flex;
    transform: scaleX(-1);
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 10;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    @media (max-width: 1024px){
        border-radius: 12px;
        left: 0;
        margin: 388px 20px 20px 20px;
        width: 90%;
        height: 926px;
        padding: 40px 16px;
    }
    @media (min-width: 1025px){
        flex-direction: column;
            position: absolute;
            margin-top: -900px;
            width: 896px;
            height: 543px;
            border-radius: 16px;
            padding: 48px;
            right: -485px;
            top: 50px;
    }
`;

export const TextAbsolut = styled.span`
    position: absolute;
    font-size: 16px;
    color: red;
`;

export const BoxWeb = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #FFF;
    position: absolute;
    margin-top: -600px;
    margin-left: 20px;
    width: 50%;
    max-height: 543px;
    padding: 48px;
`;