import React from 'react';
import { ContainerApproves } from './approves.styled';
import { V4SubTitle, V4TextSmall } from '../commons/Tipography';
import { V4TestimonialsCarousel } from './components/carousel';

export const V4ApprovesSection: React.FC = () => {
  return(
    <>
     <ContainerApproves>
        <V4SubTitle>Quem já vendeu com a gente aprova!</V4SubTitle>
        <V4TextSmall>Conheça pessoas que transformaram cotas de consórcio em dinheiro na conta de forma rápida, segura e sem complicação.</V4TextSmall>

        <V4TestimonialsCarousel />
     </ContainerApproves>
    </>
  )
};