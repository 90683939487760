import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { V4DepoimentosContainer } from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { FaCircleUser } from "react-icons/fa6";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { V4TextNameTestimonial, V4TextOcupationTestimonial, V4TextSmall } from "../../commons/Tipography";


export function V4TestimonialsCarousel() {
    const [width, setWidth] = React.useState(window.innerWidth);

    const depo = [
        {
            "texto": '"Vender minha cota de consórcio foi muito simples com a Concash. O atendimento foi excelente e o processo, rápido. Super recomendo!"',
            "cliente": "Carlos Eduardo",
            "profissao": "Advogado"
        },
        {
            "texto": '"A Concash me surpreendeu pela eficiência. Em poucos dias, consegui vender minha cota e resolver um problema antigo. Agradeço muito!"',
            "cliente": "Mariana Silva",
            "profissao": "Professora"
        },
        {
            "texto": '"Estava com dificuldades para vender minha cota, mas a Concash facilitou tudo. O atendimento foi ótimo e o pagamento, rápido."',
            "cliente": "Roberto Lima",
            "profissao": "Empresário"
        },
        {
            "texto":'"A Concash foi a melhor escolha que fiz para vender minha cota de consórcio. O processo foi transparente e sem complicações."',
            "cliente": "Ana Paula",
            "profissao": "Médica"
        },
        {
            "texto": '"Recomendo a Concash para todos que querem vender suas cotas de consórcio. O atendimento é excelente e o pagamento, rápido."',
            "cliente": "Fernando Souza",
            "profissao": "Engenheiro"
        },
        {
            "texto": '"Achei que seria complicado vender minha cota, mas a Concash tornou tudo fácil e rápido. Estou muito satisfeito com o serviço."',
            "cliente": "Lucas Pereira",
            "profissao": "Designer"
        },
        {
            "texto": '"A Concash resolveu meu problema de forma rápida e eficiente. Vendi minha cota sem complicações e recebi o pagamento em poucos dias."',
            "cliente": "Juliana Costa",
            "profissao": "Arquiteta"
        },
        {
            "texto": '"Excelente serviço! A Concash me ajudou a vender minha cota de consórcio de forma rápida e sem burocracia. Recomendo!"',
            "cliente": "Ricardo Almeida",
            "profissao": "Contador"
        }
    ];    

    React.useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div style={{ display: 'flex', width: "100%", margin: "0 auto" }}>
                <Swiper
                    modules={[Autoplay, Navigation, Pagination]}
                    spaceBetween={24}
                    margin={15}
                    slidesPerView={width <= 1024 ? 1 : 3}
                    loop
                    autoplay={{ delay: 5000 }}
                    pagination={{ clickable: true }}
                >

                    {depo.map((dep) => (                        
                    <SwiperSlide>
                        <V4DepoimentosContainer>
                            <div style={{ display: 'flex', width: '100%'}}>
                                <V4TextSmall>{dep?.texto}</V4TextSmall>
                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: "center"}}>
                                <div style={{ display: 'flex', width: 48}}>
                                <FaCircleUser size={33} />
                                </div>
                                <div style={{ display: 'flex', width: '100%', flexDirection: 'column'}}>
                                    <V4TextNameTestimonial>{dep?.cliente}</V4TextNameTestimonial>
                                    <V4TextOcupationTestimonial>{dep?.profissao}</V4TextOcupationTestimonial>
                                </div>
                            </div>
                        </V4DepoimentosContainer>
                    </SwiperSlide>
                    ))}
                   
                </Swiper>
            </div>
        </>
    );
};