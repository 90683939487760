import React from 'react';
import { V4BoxNumbersItems, V4NumbersContainer } from './numbers.styled';
import { V4SubTitle } from '../commons/Tipography';
import { V4NumbersCard } from './components/card';


export const V4NumbersSection: React.FC = () => {
        const [width, setWidth] = React.useState(window.innerWidth);
    
        React.useEffect(() => {
            const handleResize = () => setWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

  return(
    <>
        <V4NumbersContainer>
            <V4SubTitle style={{ alignSelf: width <= 1024 ? "center" : "flex-start"}}>Nossos principais números</V4SubTitle>
            <V4BoxNumbersItems>

            <V4NumbersCard type='1' />
            <V4NumbersCard type='2' />
            <V4NumbersCard type='3' />
            <V4NumbersCard type='4' />
            </V4BoxNumbersItems>
        </V4NumbersContainer>
    </>
  )
};