import React, { useState } from 'react';
import { HeadContent } from '../../../pages/v4/onboarding/onboarding.styles';
import { V4TextFooterSmall, V4TextRegular } from './Tipography';
import { GrHomeRounded } from 'react-icons/gr';
import { IoIosArrowForward } from 'react-icons/io';

interface HeadOnboardingProps {
  breadcrumb?: string;
}

export const V4HeadOnboarding: React.FC<HeadOnboardingProps> = ({ breadcrumb }) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeadContent>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
       {width > 1024 &&<div style={{ display: 'flex', flexDirection: 'row', gap: 6, alignItems: 'center' }}>
       <GrHomeRounded color="#FFF" /> 
       <a href="/" style={{ textDecoration: 'none', color: "#FFF"}}><V4TextFooterSmall>Início</V4TextFooterSmall></a>
       <IoIosArrowForward color="#FFF" />
       <V4TextFooterSmall>{breadcrumb}</V4TextFooterSmall>
        </div>}
        <div style={{ display: 'flex', flexWrap: 'wrap', color: '#FFF' }}>
          <V4TextRegular style={{ color: "#FFF", textWrap: 'wrap' }}>A Concash é uma empresa do grupo BTG Pactual e parceira das principais administradoras do Brasil.</V4TextRegular>
        </div>
      </div>

    </HeadContent>
  )
};