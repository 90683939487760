import React from "react";
import {
  V4AdminstradoraSection,
  V4Footer,
  V4Header,
} from "../../../components/V4";
import { V4Onboarding } from "../../../components/V4/commons.styled";
import { HeadContent, ImageOnboarding } from "./onboarding.styles";
import HeadOnboarding from "../../../assets/V4/onboarding/head-hero.svg";
import Woman from "../../../assets/V4/onboarding/woman.svg";
import {
  V4TextMediumBold,
  V4TextRegular,
} from "../../../components/V4/commons/Tipography";
import { V4OnboardingAdminstradoraSection } from "../../../components/V4/AdminstradoraSection";
import { V4HeadOnboarding } from "../../../components/V4/commons/HeadOnboarding";
import { Helmet } from "react-helmet-async";

// import { Container } from './styles';

const V4OnboardingSelectAdmininstradora: React.FC = () => {
  return (
    <>
          <Helmet title="Onboarding | Concash" />
          <Helmet>
            <meta
              name="descripton"
              content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
            />
          </Helmet>
      <V4Header />
      <V4Onboarding>
        <V4HeadOnboarding breadcrumb="Passo 2: Selecione a administradora" />
          
        <V4OnboardingAdminstradoraSection />
      </V4Onboarding>
      <V4Footer />
    </>
  );
};

export default V4OnboardingSelectAdmininstradora;
