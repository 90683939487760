import styled from "styled-components";
import HeadImageWeb from "../../../assets/V4/onboarding/heroweb.jpg";
import HeadImageMobile from "../../../assets/V4/onboarding/heromob.jpg";

export const HeadContent = styled.div`

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
    justify-content: flex-start;
    /* background:  #FFF; */
    @media (max-width: 1024px) {
        width: 100%;
        height: 102px;
        background-image: url(${HeadImageMobile});
        background-size: cover;
        align-items: center;
        padding: 16px;
    }
    @media (min-width: 1025px) {
        padding: 60px;
        width: 100%;
        height: 204px;
        min-height: 196px;
        flex-wrap: wrap;
        background-image: url(${HeadImageWeb});
        background-size: cover;
        
    }

`;

export const ImageOnboarding = styled.img`
    display: flex;
    position: absolute;
    align-self: flex-end;
    width: 50%;
    opacity: 0.5;
`;