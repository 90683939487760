import React from "react";
import {
  V4AdminstradoraSection,
  V4Footer,
  V4Header,
} from "../../../components/V4";
import { V4Onboarding } from "../../../components/V4/commons.styled";
import { HeadContent, ImageOnboarding } from "./onboarding.styles";
import HeadOnboarding from "../../../assets/V4/onboarding/head-hero.svg";
import Woman from "../../../assets/V4/onboarding/woman.svg";
import {
  V4TextMediumBold,
  V4TextRegular,
  V4Title,
} from "../../../components/V4/commons/Tipography";
import { V4OnboardingAdminstradoraSection } from "../../../components/V4/AdminstradoraSection";
import {
  V4ButtonSimple,
  V4ButtonSimpleWeb,
} from "../../../components/V4/commons/Button";
import IconPF from "../../../assets/V4/icons/pf.svg";
import IconPJ from "../../../assets/V4/icons/pj.svg";
import { useDispatch, useSelector } from "react-redux";
import { updatePersonType } from "../../../store/register/register.slice";
import { V4HeadOnboarding } from "../../../components/V4/commons/HeadOnboarding";
import { Helmet } from "react-helmet-async";

// import { Container } from './styles';

const V4OnboardingSelectTypePerson: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const baseLocal = useSelector((state: any) => state.register);
  const dispatch = useDispatch();

  async function handleSelectTypePerson(type: string) {
    dispatch(updatePersonType({ personType: type }));
    return (window.location.href = "/onboarding/step4");
  }

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
          <Helmet title="Onboarding | Concash" />
          <Helmet>
            <meta
              name="descripton"
              content="A Concash é o lugar mais seguro para você vender a sua cota de consórcio, ativa ou excluída. Somos uma empresa sólida e unimos tecnologia, inovação e um time altamente capacitado para oferecer a melhor negociação com o prazo de pagamento mais rápido do mercado"
            />
          </Helmet>
      <V4Header />
      <V4Onboarding>
        <V4HeadOnboarding breadcrumb="Passo 3: Quem é o titular?" />

        <div
          style={{
            display: "flex",
            padding: width > 1024 ? "40px 60px" : "24px",
          }}
        >
          <V4Title>Quem é o titular?</V4Title>
        </div>
        {width > 1024 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "40px 60px",
              gap: 24,
            }}
          >
            <V4ButtonSimpleWeb
              text="Pessoa Física"
              type="BUTTON"
              icon={<img src={IconPF} />}
              onclick={() => handleSelectTypePerson("PF")}
            />
            <V4ButtonSimpleWeb
              text="Pessoa Jurídica"
              type="BUTTON"
              icon={<img src={IconPJ} />}
              onclick={() => handleSelectTypePerson("PJ")}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "24px",
            }}
          >
            <V4ButtonSimple
              text="Pessoa Física"
              type="BUTTON"
              icon={<img src={IconPF} />}
              width={"100%"}
              onclick={() => handleSelectTypePerson("PF")}
            />
            <V4ButtonSimple
              text="Pessoa Jurídica"
              type="BUTTON"
              icon={<img src={IconPJ} />}
              width={"100%"}
              onclick={() => handleSelectTypePerson("PJ")}
            />
          </div>
        )}
      </V4Onboarding>
      <V4Footer />
    </>
  );
};

export default V4OnboardingSelectTypePerson;
