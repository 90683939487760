import styled from "styled-components";
import Hands from "../../../assets/V4/landing/hands.jpg";

export const V4HeroSectionStyled = styled.div`
    display: flex;
      @media (min-width: 1024px) {
            flex-direction: row;
            flex: 1;
        }
        
        @media (max-width: 1023px) {
            flex-direction: column-reverse;
        }
`;


export const V4FormBoxStyled = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
        @media (min-width: 1024px) {
            width: 50%;
            padding: 32px 120px 32px 66px;
            gap: 12px;
        }
        
        @media (max-width: 1023px) {
        gap: 12px;
        padding: 13px 24px;
            width: 100%;
        }
`;
export const V4ImageBoxStyled = styled.div`
    display: flex;
    background: #16888D;
    background-image: url(${Hands});
    background-position: calc(50% - 22px) center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    /* opacity: 0.7; */
    z-index: 998;
          @media (min-width: 1024px) {
            width: 50%;
            
        }
        
        @media (max-width: 1023px) {
            width: 100%;
        }
`;

export const ImageGraf = styled.img`
background: #16888D;
    opacity: 0.7;


`;

export const BoxTextHeroLanding = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    justify-items: center;
    position: absolute;
    flex-direction: column;
    @media (max-width: 1024px) {
        margin-top: 50px;
        margin-left: 60px;
    }
    @media (min-width: 1025px) {
    margin-top: 150px;
    margin-left: 160px;
        
    }
`;

export const NameAdm = styled.h3`
    font-family: 'Moderat';
    font-style: normal;
    font-weight: 400;
    @media (max-width: 1024px) {
        font-size: 48px;
        
    }
    @media (min-width: 1025px) {
        font-size: 72px;
        
    }
    line-height: 110%;

    color: #FFFFFF;
`;
export const NameAdmGreen = styled.h3`
    font-family: 'Moderat';
    font-style: normal;
    font-weight: 400;
    @media (max-width: 1024px) {
        font-size: 48px;
        
    }
    @media (min-width: 1025px) {
        font-size: 72px;
        
    }
    line-height: 110%;

    color: #A0ED38;
`;

export const TextAdmGreen = styled.h4`
    font-family: 'Moderat';
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    
    @media (max-width: 1024px) {
        font-size: 24px;
        
    }
    @media (min-width: 1025px) {
        font-size: 32px;
        
    }

    color: #A0ED38;
`;