import styled from "styled-components";

export const V4NumbersContainer = styled.section`
    box-sizing: border-box;
    display: flex;

@media (max-width: 1024px) {    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 60px;
    gap: 40px;

    width: 100%;
    height: 756px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.16);
}

@media (min-width: 1025px) {    

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 60px;
    gap: 40px;

    width: 100%;
    height: 357px;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.16);


}
`;

export const V4BoxNumbersItems = styled.div`
    display: flex;
    @media (min-width: 1025px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 64px;
    }
`;