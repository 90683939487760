import styled from "styled-components";

export const V4HeroSectionStyled = styled.div`
    display: flex;
      @media (min-width: 1024px) {
            flex-direction: row;
            flex: 1;
        }
        
        @media (max-width: 1023px) {
            flex-direction: column-reverse;
        }
`;


export const V4FormBoxStyled = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
        @media (min-width: 1024px) {
            width: 50%;
            padding: 32px 120px 32px 66px;
            gap: 12px;
        }
        
        @media (max-width: 1023px) {
        gap: 12px;
        padding: 13px 24px;
            width: 100%;
        }
`;
export const V4ImageBoxStyled = styled.div`
    display: flex;
    flex-direction: column;
          @media (min-width: 1024px) {
            width: 50%;
            
        }
        
        @media (max-width: 1023px) {
            width: 100%;
        }
`;
