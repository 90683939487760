import styled from "styled-components";

export const ContainerFooter = styled.footer`
    display: flex;
    background-color: #2E2E2E;
    width: '100%';
    @media (min-width: 1025px) {
        padding: 24px 64px;
    }
    @media (max-width: 1024px) {
        padding: 16px 28px 44px;
    }
`;