import React from 'react';
import { V4InputStyled } from './input.styled';

interface V4InputProps {
    placeholder?: string;
    onchange?: any;
    width?: any;
    color?: string;
    value?: any;
    error?: boolean;
}

export const V4Input: React.FC<V4InputProps> = ({ onchange, placeholder, color, width, value, error }) => {
    return (<>
        <V4InputStyled
            placeholder={placeholder}
            style={{ color: error? "red" : color, borderColor: error ? "red": "", width: width }}
            onChange={onchange}
            value={value}
        />
    </>)
};