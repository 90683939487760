import React, { useState } from "react";
import {
  Container,
  ContainerCookies,
  V4CookieItem,
  V4Switch,
} from "./components/index";
import { V4TextRegular, V4TextSmall } from "../commons/Tipography";
import { MdClose } from "react-icons/md";
import { V4ButtonSubmit } from "../commons/Button";
import { GoGear } from "react-icons/go";
import { Switch } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { acceptAllCookies, closeCookies } from "../../../store/optout/optout.slide";

export const V4OptOut = () => {
  const [showStart, setShowStart] = useState(true);
  const {optout}: any = useSelector(state => state);
  const dispatch = useDispatch();


  function handleAcceptAll() {
   return dispatch(acceptAllCookies());
  }



  return (
    <>
      {showStart ? (
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <V4TextRegular>Uso de cookies</V4TextRegular>
            <button style={{ border: 'none', backgroundColor: "#FFF", cursor: 'pointer' }} onClick={() => dispatch(closeCookies())}>
                <MdClose size={20} />
              </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <V4TextSmall>
              Estou de acordo com a Política de Privacidade e também declaro que
              concordo com a utilização dos meus dados de contato para fins
              comerciais pela Concash, empresas de seu grupo econômico e
              eventuais prestadores de serviço, conforme Lei Geral de Proteção
              de Dados.
            </V4TextSmall>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <V4ButtonSubmit
              text="Gerenciar cookies"
              type="BUTTON"
              onclick={() => setShowStart(false)}
              buttoncolor="transparent"
              icon={<GoGear />}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 16,
              borderTop: "1px solid #949494",
              marginTop: 16,
              paddingTop: 16,
            }}
          >
            <V4ButtonSubmit
              type="BUTTON"
              text="Rejeitar"
              width={78}
              radius={3}
              height={32}
              buttoncolor="#FFF"
              border={"1px solid rgba(0,0,0,0.86)"}
            />
            <V4ButtonSubmit
              type="BUTTON"
              text="Aceitar"
              onclick={handleAcceptAll}
              width={78}
              radius={3}
              height={32}
            />
          </div>
        </Container>
      ) : (
        <>
          <ContainerCookies>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <V4TextRegular>Uso de cookies</V4TextRegular>
              <button style={{ border: 'none', backgroundColor: "#FFF", cursor: 'pointer' }} onClick={() => setShowStart(true)}>
                <MdClose size={20} />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                height: "100%",
                gap: 10,
                margin: "20px 0",
                padding: "10px 10px 10px 0",
              }}
            >
              <V4TextSmall>Para oferecer uma melhor experiência na plataforma e serviços personalizados, utilizamos cookies. Cookies são pequenos arquivos de texto armazenados no navegador ou dispositivo. Os cookies permitem reconhecer as preferências do usuário para adaptar o website do BTG Pactual às necessidades específicas dos visitantes.</V4TextSmall>
              <V4CookieItem
                type="necessarios"
                title="Cookies estritamente necessários"
                text="Esta categoria de cookies contempla funcionalidades esenciais para o funcionamento do site, sem as quais não é possível acessar e navegar pelas páginas do BTG. Esses cookies não podem ser desativados em nosso site, no entanto, caso não tenha mais interesse em acessá-lo, é possível bloquar os cookies diretamente no navegador."
              />
              <V4CookieItem
                type="publicidade"
                title="Cookies de publicidade"
                text="Os cookies de publicidade são usados para mostrar anúncios mais relevantes e personalizados em nossa plataforma e em outros sites. Além disso, eles permitem que compartilhemos informações sobre sua navegação com nossos parceiros de publicidade, que podem usar essas informações para melhorar a eficácia dos anúncios em sites de terceiros. Caso concorde em compartilhar suas informações com nossa equipe e eventuais parceiros do BTG."
                
              />
              <V4CookieItem
                type="desempenho"
                title="Cookies de desempenho"
                text="Os cookies de desempenho, ou de análise, são utilizados para aprimorar o conteúdo do website, fornecendo informações sobre como ele está sendo usado a fim de melhorar a experiência do usuário. Eles realizam a coleta automática de determinados dados anonimizados para identificar, por exemplo, quantas vezes determinada página foi visitada. Uma vez que o uso dos dados não permite a identificação do titular de dados, o BTG Pactual optou por manter esses cookies como obrigatórios para acesso ao site.
É possível bloquear esses cookies diretamente no seu navegador, mas isso pode comprometer sua experiência e prejudicar o funcionamento do site."
              />
              <V4CookieItem
                type="estatistica"
                title="Cookies de estatística"
                text="Os cookies de estatística, como o Google Analytics, são utilizados para coletar informações sobre como você interage com o nosso site. Essas informações são utilizadas para melhorar a experiência do usuário e para ajudar a equipe do BTG a entender como o site está sendo usado. Alguns desses dados podem ser compartilhados com parceiros de terceiros para ajudar a melhorar a experiência do usuário em sites de terceiros."
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 16,
                borderTop: "1px solid #949494",
                marginTop: 16,
                paddingTop: 16,
              }}
            >
              <V4ButtonSubmit
                type="BUTTON"
                text="Fechar"
                width={78}
                radius={3}
                height={32}
                buttoncolor="#FFF"
                onclick={() => setShowStart(true)}

                border={"1px solid rgba(0,0,0,0.86)"}
              />
              <V4ButtonSubmit
                type="BUTTON"
                onclick={() => dispatch(closeCookies())}
                text="Salvar"
                width={78}
                radius={3}
                height={32}
              />
            </div>
          </ContainerCookies>
        </>
      )}
    </>
  );
};
