import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import {
  cellphoneFormater,
  formataCNPJ,
  formataCPF,
} from "../../utils/formaters";
import { maskPhone } from "../../utils/maskPhone";
import Select from "react-select";
import { GrAction } from "react-icons/gr";
import { ScreenDisplay } from "../../styles/display";
import { validateEmail } from "../../utils/validate";

export const InputText = styled.input`
  max-height: 56px;
  border: 1px solid ${colors.border};
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-display: swap;
  border-radius: 4px;
  background-color: ${colors.lightGray};

  @media (${ScreenDisplay.mobile}) {
    width: 94%;
  }

  @media (${ScreenDisplay.desktop}) {
    width: 100%;
  }
  input[type="file"] {
    background-color: ${colors.mediumGreen};
  }
`;

export const InputTextArea = styled.textarea`
  height: 120px;
  border: 1px solid ${colors.border};
  padding: 10px;
  font-family: "Roboto", sans-serif;
      font-display: swap;
  border-radius: 10px;
  background-color: "#FFF;

  input[type="file"] {
    background-color: ${colors.mediumGreen};
  }
`;

export const InputEmail = (props: any) => {
  const [text, setText] = useState("");
  const [error, setError] = useState(false);

  const handleChangeText = (text: string) => {
    const texto = text?.replaceAll(" ", "");
    if (text) {
      if (validateEmail(texto)) {
        setError(false);
        setText(texto);
        props.action(texto);
      } else {
        setError(true);
        setText(texto);
        props.action(texto);
      }
    } else {
      setError(false);
      setText(texto);
      props.action(texto);
    }
  };

  return (
    <InputText
      value={text}
      onChange={(e) => {
        if (e.target.value) {
          handleChangeText(e.target.value);
          props.action(e.target.value);
        } else {
          handleChangeText("");
          props.action("");
        }
      }}
      name={props.name}
      id={props.id}
      type="email"
      required
      style={{ backgroundColor: error ? "rgba(255, 0, 0, 0.49)" : "#f2f2f2" }}
    />
  );
};

export const InputPhone = (props: any) => {
  const [text, setText] = useState("");
  const [error, setError] = useState(props.error);

  const handleChangeText = (texto: string) => {
    const number = texto.replace(/[^0-9]/g, "");
    const tamanho = number.length;

    const masked = cellphoneFormater(number);

    if (number && number !== "") {
      if (texto.length < 14) {
        setError(true);
      } else {
        setError(false);
      }
      setText(masked);
      props.action(masked);
    } else {
      setText(texto);
      setError(true);
      props.action(masked);
    }
  };

  return (
    <InputText
      value={text}
      onChange={(e) => {
        handleChangeText(e.target.value);
        props.action(e.target.value);
      }}
      name={props.name}
      id={props.id}
      type="text"
      required
      style={{ backgroundColor: error ? "rgba(255, 0, 0, 0.49)" : "#f2f2f2" }}
    />
  );
};

export const InputCpfCnpj = (props: any) => {
  const [text, setText] = useState("");

  const handleChangeText = (texto: string) => {
    const validating = texto.replace(/[^\d]/g, "");
    if (validating.length > 11) {
      const cnpj = formataCNPJ(texto);
      setText(cnpj);
      props.action(cnpj);
    } else {
      const cpf = formataCPF(texto);
      setText(cpf);
      props.action(cpf);
    }
  };

  return (
    <InputText
      value={text}
      onChange={(e) => {
        handleChangeText(e.target.value);
        props.action(e.target.value);
      }}
      name={props.name}
      id={props.id}
      type="text"
      required
      style={{ backgroundColor: "#f2f2f2" }}
    />
  );
};

export const InputDoc = (props: any) => {
  const handleChangeDoc = (dc: any) => {
    props.action(dc);
  };

  return (
    <InputText
      name={props.name}
      id={props.id}
      value={props.value}
      type="file"
      accept=".pdf"
      onChange={props.onChange}
      style={{ backgroundColor: "#f2f2f2" }}
    />
  );
};
