import styled from "styled-components";

export const V4InputStyled = styled.input`
    background: transparent;
    border: none;
    padding: 13px 0;
    border-bottom: 1px solid;

font-family: 'Moderat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 150%;
padding: 6px;
/* identical to box height, or 21px */
font-feature-settings: 'pnum' on, 'lnum' on;

color: rgba(0, 0, 0, 0.64);

&:focus {
    border-bottom-color: blue;
    color: blue;
    background-color:rgb(231, 231, 231);
    outline: none; 
  }

`;